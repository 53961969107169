import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { Footer } from 'components/Footer/Footer'
import SnackBarMsg from 'components/SnackBar/SnackBarMsg'
import { usePermissionChecker } from 'containers/helper/hooks/usePermissionChecker'
import { useSnackBar } from 'containers/helper/hooks/useSnackBar'
import { IAction } from 'containers/helper/models'
import { amtStr } from 'containers/helper/numFormatter'
import { Filter } from 'containers/helper/SearchFilter'
import ShareDialog, { htmlConverter } from 'containers/helper/Share'
import {
  HotelPermission,
  TaxType,
  useBiSharedGAlleryInsertMutation,
  useBiUsersLisitingQuery,
  useDocumentTemplateListQuery,
  useEmailSendMutation,
  useFolioDetailsLazyQuery,
  useFolioDetailsWithIDsLazyQuery,
  useFolioHistoryListingQuery,
  useGetHotelLatestTaxQuery,
  useHotelDetailsQuery,
  useDepositClassQuery,
  useOutGoingDocTemplateLazyQuery,
  useDebtorFolioInfoQuery,
  useDebtorDetailQuery
} from 'generated/graphql'
import React, { useEffect, useState, Reducer, useReducer } from 'react'
import { useHistory, useLocation } from 'react-router'
import { TypesRoute } from '../../../BusinessInsightModule/BusinessRoute'
import AddressConverter from '../../../BusinessInsightModule/BIDigitalDocument/addressConverter'
import { DocSelectionDialog } from '../../../BusinessInsightModule/BIDigitalDocument/DocSelectionDialog'
import { useReceipt } from '../../../BusinessInsightModule/BIDigitalDocument/hooks/useReceipt'
import { useInvoice } from '../../../BusinessInsightModule/BIDigitalDocument/hooks/useInvoice'
import { usePreventRefresh } from 'containers/helper/hooks/usePreventRefresh'
import { useGetFolioChargesInfo } from 'containers/helper/hooks/useGetFolioChargesInfo'
import { HotelXFolio } from '../../../BusinessInsightModule/BIDigitalDocument/CustomizedDocument/Default/Folio'
import { SuspendDocumentPromenadeBintulu } from './../SuspendDocumentCustom/SuspendDocumentPromenadeBintulu'
import { SuspendDocumentPromenadeKK } from './../SuspendDocumentCustom/SuspendDocumentPromenadeKK'
import { useGetTaxScheme } from '../../../CommonSettingsModule/TaxScheme/hooks/useGetTaxScheme'
import RefreshButton from 'components/Button/RefreshButton'
import ImperialLogo from 'assets/images/HotelImperial/ImperialLogo.jpg'
import ImperialLogoKuching from 'assets/images/HotelImperial/ImperialLogoKuching.jpg'
import { differenceInDays } from 'date-fns'

interface Props {
  mode?: string
  type?: string
}
interface Document {
  dialog?: boolean
  signatureUrl?: null | undefined
}
interface props {
  DocSelectionDialog?: boolean
  ButtonType?: string
  DocList?: any[]
  DocSelected?: string
}
export const SuspendDocumentImperial = (props: Props) => {
  const { handleKeyDown, handleRightClick } = usePreventRefresh()
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('contextmenu', handleRightClick)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('contextmenu', handleRightClick)
    }
  }, [])
  const dateFormat = require('dateformat')
  const { mode, type } = props
  const history = useHistory()
  const [simple, setSimple] = useState(false)
  const [imgUrl, setimgUrl] = useState(null)
  const location = useLocation()
  const { handlePermDisabled } = usePermissionChecker()
  const [emailShare, setEmailShare] = useState(false)
  const [sendEmail, setSendEmail] = useState(false)
  const [EmailAddress, setEmailAddress] = useState('')
  const [folioInfo, setFolioInfo] = useState(null)
  const { snackFunc, snackBarMessage, openSnackBar } = useSnackBar()
  const [setFolioData, chargesData] = useGetFolioChargesInfo()
  
  const stateInfo: any = location.state

  // console.log("state info: ", stateInfo)
  const TransactionType = {
    Package: 'Package',
    Rate: 'Rate',
  }

  const [
    Template,
    {
      loading: Loadingtemp,
      data: { OutGoingDocTemplate } = {
        OutGoingDocTemplate: null,
      },
    },
  ] = useOutGoingDocTemplateLazyQuery({
    variables: {
      HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    Template()
  }, [HotelXFolio])

  const { data: { HotelDetails } = { HotelDetails: null } } =
    useHotelDetailsQuery({
      variables: {
        HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
      },
      fetchPolicy: 'no-cache',
    })
  const [
    Details,
    {
      refetch,
      loading: Loadings,
      data: { FolioDetails } = {
        FolioDetails: null,
      },
    },
  ] = useFolioDetailsLazyQuery({
    variables: {
      ID: stateInfo?.FolioID,
      HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
    },
    fetchPolicy: 'no-cache',
  })

  const {
    data: { DebtorDetail } = { DebtorDetail: null },
  } = useDebtorDetailQuery({
    fetchPolicy: 'network-only',
    variables: {
      ID: FolioDetails?.NonGuestInfo?.DebtorID,
      // HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
    },
  })

  const iseInvoice = () => {
    const iseInvoice = JSON.parse(localStorage.getItem('Hotel'))?.Company?.isEInvIntegrated === true
    const startDate = JSON.parse(localStorage.getItem('Hotel'))?.Company?.eInvStartDate
    const endDate = JSON.parse(localStorage.getItem('Hotel'))?.Company?.eInvEndDate

    const eInvoice = iseInvoice && 
      (dateFormat(FolioDetails?.ClosedDate, "yyyy-mm-dd") >= dateFormat(startDate, "yyyy-mm-dd")) &&
      (dateFormat(FolioDetails?.ClosedDate, "yyyy-mm-dd") <= dateFormat(endDate, "yyyy-mm-dd"))

    return eInvoice === true ? true : false
  }


  const RoomChargesGrouping = (RoomChargesListing) => {

    const resArr = RoomChargesListing?.reduce((acc, curr) => {

      const existing = acc.find((x) => 
        x.Description === curr.Description && 
        x.TrxDate === curr.TrxDate && 
        x.RegistrationID === curr.RegistrationID &&
        x.TrxDescription === curr.TrxDescription &&
        x.FromRegistration === curr.FromRegistration
      )
  
      if (existing) {
        existing.BaseAmount += Number(curr.BaseAmount)
        existing.ServiceCharge += Number(curr.ServiceCharge)
        existing.TaxAmount += Number(curr.TaxAmount)
        existing.TrxAmount += Number(curr.TrxAmount)
      } else {
        acc.push({...curr, TaxRoomLegder: []})
      }
      
      return acc
    }, [])

    return resArr
  }

  const TaxSchemeCalc = (taxdata) => {
    const TaxArr = [].concat(
      ...taxdata?.map(x => {
        if (x?.TaxBillLedger?.length > 0 ) { 
          return x?.TaxBillLedger 
        } else if (x?.TaxRoomLedger?.length > 0) {
          return x?.TaxRoomLedger
        } else {
          return
        }
      })?.filter(y => y !== undefined)
    )

    const NewTaxArr = TaxArr?.reduce((acc, curr) => {
      const { TaxID, TaxInfo, TaxAmount, TaxRate } = curr

      const existing = acc.find((x) => x.TaxID === TaxID)

      if (existing) {
        existing.TaxAmount += TaxAmount
      } else {
        acc.push({TaxID, TaxRate, TaxInfo, TaxAmount})
      }

      return acc;
    }, [])

    return NewTaxArr
  }

  const TaxSchemeCalc2 = (taxSchemeLedger) => {    

    const NewTaxArr = taxSchemeLedger?.reduce((acc, curr) => {
      const { TaxID, TaxInfo, TaxAmount, TaxRate } = curr

      const existing = acc.find((x) => x.TaxID === TaxID)

      if (existing) {
        existing.TaxAmount += TaxAmount
      } else {
        acc.push({TaxID, TaxRate, TaxInfo, TaxAmount})
      }

      return acc;
    }, [])
    return NewTaxArr
  }

  useEffect(() => {
    if (stateInfo?.FolioID && stateInfo?.SelectedBilling?.length > 0 &&
        FolioDetails?.FolioNo === null && FolioDetails?.ClosedDate === null
    ) {

      // room charge/room ledger/text ledger filter by trx type, date and regid

      const filteredRoomCharge = FolioDetails?.RoomCharges?.map( x => {
        const selectedRoomCharges = stateInfo?.SelectedBilling?.filter(
          y =>
            y.TransactionType === 'RoomLedger' &&
            dateFormat(y.EffectiveDate, 'yyyy-mm-dd') ===
              dateFormat(x.TrxDate, 'yyyy-mm-dd') &&
            y.RegistrationID === x.RegistrationID
        )

        if (selectedRoomCharges?.length > 0) {
          return x
        } else return null

      }).filter(z => z !== null)

      const filteredRoomLedger = FolioDetails?.RoomLedger?.map( x => {
        const selectedRoomCharges = stateInfo?.SelectedBilling?.filter(
          y =>
            y.TransactionType === 'RoomLedger' &&
            dateFormat(y.EffectiveDate, 'yyyy-mm-dd') ===
              dateFormat(x.TrxDate, 'yyyy-mm-dd') &&
            y.RegistrationID === x.Registration.ID
        )

        if (selectedRoomCharges?.length > 0) {
          return x
        } else return null

      }).filter(z => z !== null)

      const filteredRoomChargeSuspend = FolioDetails?.RoomChargesSuspend?.map( x => {
        const selectedRoomChargeSuspend = stateInfo?.SelectedBilling?.filter(
          y =>
            dateFormat(y.EffectiveDate, 'yyyy-mm-dd') ===
              dateFormat(x.TrxDate, 'yyyy-mm-dd') &&
            y.RegistrationID === x.RegistrationID
        )

        if (selectedRoomChargeSuspend?.length > 0) {
          return x
        } else return null

      }).filter(z => z !== null)

      const filteredTaxLedger = FolioDetails?.TaxLedger?.map( x => {
        const selectedTaxLedger = stateInfo?.SelectedBilling?.filter(
          y => y.TransactionType === 'TaxLedger' && y.ID === x.ID
        )

        if (selectedTaxLedger?.length > 0) {
          return x
        } else return null

      }).filter(z => z !== null)

      const filteredBillLedger = FolioDetails?.BillLedger?.map( x => {
        const selectedTBillLedger = stateInfo?.SelectedBilling?.filter(
          y => (y.TransactionType === "BillLedger" || y.Type === "BillLedger") && y.ID === x.ID
        )
        const findVoidTrxWithSameDay = stateInfo?.SelectedBilling?.map(yi => {
          let SelectedBillingObj = {
            ...yi,
            CompareTrxDate: yi?.EffectiveDate ? yi?.EffectiveDate : yi?.TrxDate,
          }
          return SelectedBillingObj
        })?.filter(
          y => (
            y.ReversedID !== null &&
            y.ReversedID === x.ReversedBillLedgerID &&
            dateFormat(y?.CompareTrxDate, "yyyy-mm-dd") === dateFormat(x?.TrxDate, "yyyy-mm-dd")
          )
        )
        console.log(selectedTBillLedger,"selectedTBillLedger")
        console.log('findVoidTrxWithSameDay', findVoidTrxWithSameDay)

        if (selectedTBillLedger?.length > 0 && findVoidTrxWithSameDay?.length < 2) {
          
          return x
        } else return null

      }).filter(z => z !== null)

      const filteredPayment = FolioDetails?.Payment?.map( x => {
        const selectedPayment = stateInfo?.SelectedBilling?.filter(
          y => y.ID === x.ID
        )

        if (selectedPayment?.length > 0) {
          return x
        } else return null

      }).filter(z => z !== null)

      let taxSchemeLedger = TaxSchemeCalc2(TaxSchemeCalc(filteredBillLedger || []).concat(TaxSchemeCalc(filteredRoomChargeSuspend || [])))

      const data = {
        ...FolioDetails,
        RoomCharges: filteredRoomCharge,
        RoomLedger: filteredRoomLedger,
        TaxLedger: filteredTaxLedger,
        RoomChargesSuspend: filteredRoomChargeSuspend,
        BillLedger: filteredBillLedger,
        Payment: filteredPayment,
        TaxSchemeLedger: taxSchemeLedger,
        TaxSchemeRoomLedger: TaxSchemeCalc(filteredRoomChargeSuspend || []),
      }

      setFolioInfo(data)
      setFolioData(data) // hook to get folio amount
    } else {
      const filteredBillLedger2 = FolioDetails?.BillLedger?.map( x => {
        const findVoidTrxWithSameDay = FolioDetails?.BillLedger?.filter(
          y => (
            y.ReversedBillLedgerID !== null &&
            y.ReversedBillLedgerID === x.ReversedBillLedgerID &&
            dateFormat(y?.TrxDate, "yyyy-mm-dd") === dateFormat(x?.TrxDate, "yyyy-mm-dd")
          )
        )

        if (findVoidTrxWithSameDay?.length < 2) { return x }
        else { return null }
      })?.filter(z => z !== null)

      const filteredBillLedgernonEO = filteredBillLedger2?.filter(x => 
        x.IncidentalCharge?.IsOutlet !== true && 
        x.IncidentalCharge?.IsEventInterface !== true &&
        x.IncidentalCharge?.isEInvIntegrated === true
      )

      const filteredBillLedgerEO = filteredBillLedger2?.filter(x => 
        x.IncidentalCharge?.IsOutlet === true || 
        x.IncidentalCharge?.IsEventInterface === true ||
        x.IncidentalCharge?.isEInvIntegrated !== true
      )

      let taxSchemeLedger = TaxSchemeCalc2(TaxSchemeCalc(filteredBillLedger2 || []).concat(TaxSchemeCalc(FolioDetails?.RoomLedger || [])))

      let taxSchemeLedgernonEO = TaxSchemeCalc2(
        TaxSchemeCalc(filteredBillLedgernonEO || [] ).concat(TaxSchemeCalc(FolioDetails?.RoomLedger || []))
      )
      
      let taxSchemeLegerEO = TaxSchemeCalc2(
        TaxSchemeCalc(filteredBillLedgerEO || [] ).concat(TaxSchemeCalc(FolioDetails?.RoomLedger || []))
      )
      
      const data2 = {
        ...FolioDetails,
        BillLedger: iseInvoice() === true && FolioDetails?.ClosedDate !== null ? filteredBillLedgernonEO : filteredBillLedger2,
        BillLedgerEO: iseInvoice() === true ? filteredBillLedgerEO : [], 
        TaxSchemeLedger: iseInvoice() === true && FolioDetails?.ClosedDate !== null ? taxSchemeLedgernonEO : taxSchemeLedger,
        TaxSchemeLedgerEO: iseInvoice() === true ? taxSchemeLegerEO : [],
        TaxSchemeRoomLedger: TaxSchemeCalc(FolioDetails?.RoomLedger  || []),
        Payment: FolioDetails?.Payment?.filter(y => !y.ReversedPaymentID)
      }

      setFolioInfo(data2)
      setFolioData(data2) // hook to get folio amount
    }
  }, [stateInfo?.FolioID, FolioDetails?.ID])

  const Payment = folioInfo?.Payment
    ? folioInfo?.Payment.filter(x => x.PaymentClass === 'Refund').length
    : null

  const Charge = folioInfo?.Payment
    ? folioInfo?.Payment.filter(x => {
        return x.PaymentClass === 'Refund'
      }).length > 0
      ? folioInfo.FolioNo
      : null
    : null

  // console.log(FolioDetailsWithIDs)

  const isNonGuestFolio = folioInfo?.FolioType === 'NonGuest'
  const NonGuestFolioTitle = folioInfo?.NonGuestInfo?.Title
  const NonGuestFolioTotal =
    (folioInfo?.RoomCharges?.reduce((acc, curr) => {
      return acc + curr.TrxAmount
    }, 0) || 0) +
    (folioInfo?.BillLedger?.reduce((acc, curr) => {
      return acc + curr.TrxAmount
    }, 0) || 0) +
    (folioInfo?.TaxLedger?.reduce((acc, curr) => {
      return acc + curr.TaxAmount
    }, 0) || 0) -
    (OutGoingDocTemplate === null ? 
    (!folioInfo?.FolioNo
      ? folioInfo?.Payment?.reduce((acc, curr) => {
          return acc + curr?.PaymentClass === 'Refund' ? -curr.Amount : curr.Amount
        }, 0) || 0
      : 0)

      : 0+0)

  useEffect(() => {
    if (stateInfo?.FolioID) {
      Details()
    }
  }, [stateInfo?.FolioID])



  console.log('folioInfo', folioInfo)

  const Einvoice =FolioDetails?.QrCodeUrl === null && FolioDetails?.is_einvoice === true && iseInvoice()


  const [EmailSend, { loading: loadingInsert }] = useEmailSendMutation()
  const ShareButton = {
    title: `${
      JSON.parse(localStorage.getItem('Hotel'))?.HotelName
    } has shared you a folio-${folioInfo?.FolioNo} `,
    url: imgUrl,
    img: `Click Here`,
  }

  var tourismTaxAmount = folioInfo?.TaxLedger?.map(x => {
    if (x.TaxType === TaxType.TourismTax) {
      return x.TaxAmount
    } else {
      return 0
    }
  }).reduce((a, b) => a + b, 0)

  var Heritage = !folioInfo?.TaxLedger
    ? 0
    : folioInfo?.TaxLedger.map(x => {
        if (x.TaxType === TaxType.HeritageTax) {
          return x.TaxAmount
        } else {
          return 0
        }
      })?.reduce((a, b) => a + b, 0)

  var Levy = !folioInfo?.TaxLedger
    ? 0
    : folioInfo?.TaxLedger.map(x => {
        if (x.TaxType === TaxType.Levy) {
          return x.TaxAmount
        } else {
          return 0
        }
      }).reduce((a, b) => a + b, 0)
  var StateFund = !folioInfo?.TaxLedger
    ? 0
    : folioInfo?.TaxLedger?.map(x => {
        if (x.TaxType === TaxType.StateFund) {
          return x.TaxAmount
        } else {
          return 0
        }
      }).reduce((a, b) => a + b, 0)
  var TourismTax = folioInfo?.TaxLedger?.map(x => {
    if (x.TaxType === TaxType.TourismTax) {
      return x.TaxAmount
    } else {
      return 0
    }
  })?.reduce((a, b) => a + b, 0)

  const {  data: { DebtorFolioInfo } = {     DebtorFolioInfo: null,  }, } =
  useDebtorFolioInfoQuery({
      variables: {
        HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
        DebtorAccount : folioInfo?.Payment?.find(x => x?.DebtorAccount !== null && x.PaymentType === "CityLedger")?.DebtorAccount || DebtorDetail?.DebtorAccount || null
      },
      fetchPolicy: 'no-cache',
    })

  useEffect(() => {
    var Email = ''
    Email =
      folioInfo?.Registration !== null
        ? folioInfo?.Registration?.Guest?.Contact.Email
        : folioInfo?.Booking?.Registration?.filter(
            x => x?.IsPrincipal === true
          )[0]?.Guest?.Contact?.Email

    if (Email !== '') {
      setEmailShare(true)
      setEmailAddress(Email)
    }
  }, [folioInfo])
  const isnonguestandsuspend = () => {
    return OutGoingDocTemplate !== null && stateInfo?.Isnonguestsuspend === true
      ? true
      : false
  }

  const isnegativeamount  = (Amount_:number,payment) =>{
    
    const converterbar = Number(Amount_) < 0 
         ?
       
          `(${amtStr((Amount_)*-1)})`
          :  `${amtStr((Amount_))}`
   
          const converterbar2 = Number(Amount_) < 0 

          return payment?.hasOwnProperty('PaymentType') && 
            ((payment?.DocumentType === null && !payment?.ReceiptNo?.startsWith('HTRF')) ||
            (payment?.DocumentType !== null && !payment?.DocumentType?.startsWith('HTRF'))) && 
            !converterbar2 
              ? `(${ converterbar})` 
              : converterbar
     }

  useEffect(() => {
    sendEmail &&
      EmailSend({
        variables: {
          Body: {
            Header: `${ShareButton.title}`,
            Body: FormContent,
          },
          HotelID: JSON.parse(localStorage.getItem('Hotel')).ID,
          Email: EmailAddress,
        },
      }).then(w => {
        if (w.data.EmailSend === true) {
          snackFunc('Sent Email Successfully!', false)
          setSimple(false)
        } else {
          snackFunc('Sent Email Fail!', false)
        }
      })
  }, [sendEmail])

  const displayTotal = (amt: any, PackagesPrice: any) => {
    let display
    const data = amt.DepositAmount
      ? amt.DepositAmount
      : PackagesPrice
      ? amt.BaseAmount + PackagesPrice
      : amt.BaseAmount
      ? amt.BaseAmount
      : amt.TaxAmount
      ? amt.TaxAmount
      : amt.Amount

    if (data < 0) {
      display = `(${amtStr(Math.abs(data))})`
    } else if (data > 0) {
      display = amtStr(data)
    }

    return display
  }

  const { data } = useGetTaxScheme({
    TaxOutput: folioInfo?.TaxSchemeLedger || [],
    Type: 'NonGuest',
  })

  const HotelMayaTax = (folioInfo?.TaxSchemeLedger || [])?.map(x => {
    return `
    <tr
      class="" 
      style="background-color: #fff;
      color: #2c2c2c;
      font-size: 10px;
      line-height: 15px;"
    >
      <td
        style="font-weight: 300; padding-left: 0px;"
      >
        ${x?.TaxInfo?.code || x?.taxCode || x?.TaxCode} (${x?.TaxRate || x?.taxRate || x?.TaxRate}%)
      </td>
      <td
        style="font-weight: 300; item-align:right; text-align: right;"
      >
        ${isnegativeamount(Number(x?.TaxAmount || x?.taxAmount || x?.Value || 0.00), '')}
      </td>
    </tr>
  `
  })

  var docItem = Object.assign({}, HotelMayaTax)
  //Object to string
  var HotelMayaTaxItem = ''
  for (const [key, value] of Object.entries(docItem)) {
    HotelMayaTaxItem = HotelMayaTaxItem + value
  }

  let PackagesPrice = 0
  var NumberCount = 1
  // const SelectedItem =[FolioDetails?.BillLedger, FolioDetails?.RoomCharges, FolioDetails?.Payment?.filter(x=>x?.PaymentClass === 'Advance')].filter(Boolean).flat(10) as any;
  const SelectedItem = [
    console.log(folioInfo, "apatu"),
    folioInfo?.BillLedger,
    RoomChargesGrouping(folioInfo?.RoomChargesSuspend),
    folioInfo?.TaxLedger,
    folioInfo?.FolioNo ? [] : folioInfo?.Payment,
  ]
    ?.filter(Boolean)
    ?.flat(10) as any

  const selectedItemDesc = stateInfo?.Page === 'PaymentFolio' &&
    SelectedItem?.filter(y => y?.PaymentClass === 'Refund')?.length > 0
      ? SelectedItem
      : SelectedItem

  var desc = selectedItemDesc
    ?.sort((x, y) => (x.TrxDate > y.TrxDate ? 1 : -1))
    ?.map((x, i) => {
      // console.log(x, "apatu")
      NumberCount += 1
      //  console.log(NumberCount)
      return `
      
      <tr  
      class="room-detail" 
      style="
      font-family: 'Poppins';
      -webkit-print-color-adjust: exact;
      -webkit-text-size-adjust:none;
      border: 2px solid #fff;
      background-color: #fff;
      color:'#212121';
        font-size: 10px;
        line-height: 15px;
        text-align: center;">
    <td style={"text-align: center; font-family: 'Poppins';
    -webkit-print-color-adjust: exact;
    -webkit-text-size-adjust:none;
    border: 2px solid #fff;"}>${dateFormat(
      x.TrxDate || x.PaymentDate,
      'dd/mm/yyyy'
    )}</td>
    <td style="text-align: left; line-height: 1.2; font-family:'Poppins';">
    ${
      // console.log("x: ", x)
     
      !x.PaymentClass
        ? !x.IncidentalCharge
          ? !x.DepositClass
            ? x?.TrxDescription
              ? x.TrxDescription
              : x?.Description
              ? `${x?.Description} : ${
                  folioInfo?.Registration
                    ? folioInfo?.Registration?.Room?.RoomNo
                    : folioInfo?.Booking?.Registration?.find(
                        y => y?.ID === x?.RegistrationID
                      )?.Room?.RoomNo || ''
                } : 
            ${
              folioInfo?.Registration
                ? folioInfo?.Registration?.Guest?.Contact?.FullName
                : folioInfo?.Booking?.Registration?.find(
                    y => y?.ID === x?.RegistrationID
                  )?.Guest?.Contact?.FullName || ''
            }`
              : x.TaxType
              ? (x?.TaxDescription || x.TaxType)
              : x?.TransactionType
            : x.DepositClass
          : x.Description
          ? x.Description
          : x.IncidentalCharge.Description
        : x.PaymentClass === 'Refund'
        ? `Refund by ${x.PaymentType === "BankTT" ? "Bank TT" : x?.PaymentType}`
        : `${x.PaymentType === "BankTT" ? "Bank TT" : x?.PaymentType} Payment`
    } 
    </td>
    ${
      OutGoingDocTemplate !== null
        ? `<td style="text-align: center; font-family:'Poppins';">${
            x.Qty !== undefined ? x.Qty : '1'
          }</td>`
        : `<td style="text-align: center; font-family:'Poppins';">${
         ''
        }</td>`
    }
    <td style="font-family: 'Poppins';
    -webkit-print-color-adjust: exact;
    -webkit-text-size-adjust:none;
    border: 2px solid #fff;">${
      !x.ReferenceNo ? (!x?.CardNo ? '' : x?.CardNo) : x.ReferenceNo
    }</td>
    <td style="text-align: right; font-family: 'Poppins';
    -webkit-print-color-adjust: exact;
    -webkit-text-size-adjust:none; padding-right: 2%;">${
      
      isnegativeamount(
      Number(
      x.DepositAmount
        ? x.DepositAmount
        : PackagesPrice
        ? x.BaseAmount + PackagesPrice
        : x.BaseAmount
        ? x.BaseAmount
        : x.TaxAmount
        ? x.TaxAmount
        : x.Amount
    ),x) }</td>
    </tr>
    `
    })
  //Arr to Object
  var item = Object.assign({}, desc)
  //Object to string
  var EachFolioItem = ''
  for (const [key, value] of Object.entries(item)) {
    EachFolioItem = EachFolioItem + value
  }
  // console.log(stateInfo, mode, 'stateInfo')

  /// Disbursement & Reimbursement Item
  //* start */
  const SelectedItemEO = FolioDetails?.ClosedDate !== null
    ? folioInfo?.BillLedgerEO?.sort((x, y) => (x.TrxDate > y.TrxDate ? 1 : -1))
    : []
  
  const subTotalEO = SelectedItemEO?.reduce((acc, curr) => {
    return acc + curr?.BaseAmount
  }, 0)

  const TotalEO = folioInfo?.BillLedgerEO?.reduce((acc, curr) => {
    return acc + curr?.TrxAmount
  }, 0)
  const GrandTotal = Number(chargesData.Total) + Number(TotalEO)

  var descEO = SelectedItemEO?.map(x => {
    return `
      <tr class="room-detail" 
        style="font-family: 'Poppins'; -webkit-print-color-adjust: exact; 
        -webkit-text-size-adjust:none; border: 2px solid #fff; background-color: #fff; 
        color: #212121; font-size: 10px; text-align: center;"
      >
        <td style="text-align: center; font-family: 'Poppins';">
          ${dateFormat(x.TrxDate || x.PaymentDate, 'dd/mm/yyyy')}
        </td>
        <td style="text-align: left; line-height: 1.2; font-family: 'Poppins';">
          ${!x.IncidentalCharge ? x.Description : x.IncidentalCharge.Description} 
        </td>
        ${OutGoingDocTemplate !== null
          ? `<td style="text-align: center; font-family:'Poppins';">
              ${x.Qty !== undefined ? x.Qty : '1'}
            </td>`
          : `<td style="text-align: center; font-family:'Poppins';">
              ${''}
            </td>`
        }
        <td style="text-align: left; line-height: 1.2; font-family: 'Poppins'; border: 2px solid #fff;">
          ${!x.ReferenceNo ? '' : x.ReferenceNo}
        </td>
        <td style="text-align: right; line-height: 1.2; padding-right: 2%; font-family: 'Poppins';">
          ${isnegativeamount(Number(x.BaseAmount), x)}
        </td>
      </tr>
    `
  })

  var itemEO = Object.assign({}, descEO)
  var EachFolioItemEO = ''
  for (const [key, value] of Object.entries(itemEO)) {
    EachFolioItemEO = EachFolioItemEO + value
  }

  const taxListingEO = () => {
    var EachTaxItemEO = ''

    if (folioInfo?.TaxSchemeLedgerEO?.length > 0) {
      const taxListArr = folioInfo?.TaxSchemeLedgerEO?.map((x) => {
        return `
          <tr
            class="" 
            style="background-color: #fff;
            color: #2c2c2c;
            font-size: 10px;
            line-height: 15px;"
          >
            <td
              style="font-weight: 300; padding-left: 0px;"
            >
              ${x?.TaxInfo?.code} (${x?.TaxRate}%)
            </td>
            <td
              style="font-weight: 300; item-align:right; text-align: right;"
            >
              ${isnegativeamount(Number(x?.TaxAmount || 0.00), '')} 
            </td>
          </tr>
        `
      });

      var docItem = Object.assign({}, taxListArr)
      //Object to string
      for (const [key, value] of Object.entries(docItem)) {
        EachTaxItemEO = EachTaxItemEO + value
      }
    }

    return folioInfo?.TaxSchemeLedgerEO?.length > 0 ? EachTaxItemEO : ''
  }

  const externalOutlet = SelectedItemEO?.length > 0 
    ? `<table width="100%" border="0" cellspacing="0" cellpadding="0" style="padding-bottom: 5px">
      <tr style="font-family: 'Poppins'; -webkit-print-color-adjust: exact; -webkit-text-size-adjust:none; 
        background-color: #FFFFFF; color: #FFFFFF; font-size: 10px; line-height: 28px; text-align: center;"
      >
        <th style="font-weight: 500;width:150px;"></th>
        <th style="font-weight: 500; width:315px; "></th>
        <th style="font-weight: 500;  width:100px;"></th>
        <th style="font-weight: 500;  width:95px;"></th>
        <th style="font-weight: 500;  width:100px;"></th>
      </tr>
      <tr style="background-color: #fff; color: #2c2c2c; font-size: 10px; line-height: 15px;">
        <td colspan="5" style="font-weight: 800; text-decoration: underline; padding-bottom: 2px; padding-left: 6%">
          Disbursement/Reimbursement
        </td>
      </tr>
      ${EachFolioItemEO}
    </table>
    <div style="width: 100%; display: flex; margin-top: 10px; padding-bottom: 10px">
      <div style="width: 50%;  margin: 4px; padding: 6px; "></div>
      <div style="width: 50%; display: block; padding-left: 15%;padding-right: 6px;">
        <table 
          width="100%" border="0" cellspacing="0" cellpadding="0" 
          style="margin-bottom: 10px; font-family: 'Poppins';
            -webkit-print-color-adjust: exact; -webkit-text-size-adjust:none;"
        >
          <tr 
            class="" 
            style="background-color: #fff;
            color: #2c2c2c;
            font-size: 10px;
            line-height: 15px;"
          >
            <td style="font-weight: 300; padding-left: 0px;">
              Sub Total
            </td>
            <td style="font-weight: 300; item-align:right; text-align: right;">
              ${isnegativeamount(Number(subTotalEO.toFixed(2)), '')}
            </td>
            <td style="width:4%;"></td>
          </tr>
          <tr 
            class="" 
            style="font-family: 'Poppins'; -webkit-print-color-adjust: exact; 
            -webkit-text-size-adjust:none; background-color: #fff;
            color: #2c2c2c; font-size: 10px; line-height: 15px;"
          ></tr>
          <tr 
            class="" 
            style="background-color: #fff; color: #2c2c2c;
            font-size: 10px; line-height: 15px;"
          >
            ${taxListingEO()}
          </tr>
          <tr 
            class="" 
            style="background-color: #fff; color: #2c2c2c;
            font-size: 10px; line-height: 15px;"
          >
            <td style="font-weight: 800; padding-left: 0px;">
              TOTAL
            </td>
            <td style="font-weight: 800;  item-align:right; text-align: right; color:#2c2c2c">
              ${isnegativeamount(Number(TotalEO.toFixed(2)), '')}
            </td>
            <td style="width:4%;"></td>
          </tr>
          <tr class="" style="background-color: #fff; color: #2c2c2c; font-size: 10px; line-height: 1px;">
            <td style="font-weight: 800; padding-left: 0px; padding-top: 10px;">
              GRAND TOTAL
            </td>
            <td style="font-weight: 800;  item-align:right; text-align: right; color:#2c2c2c; padding-top: 10px;">
              ${isnegativeamount(Number(GrandTotal.toFixed(2)), '')}
            </td>
            <td style="width:4%;"></td>
          </tr>
        </table>
      </div>
    </div>`
  : ``
  //* end */

  const {
    loading,
    data: { DocumentTemplateList } = { DocumentTemplateList: null },
  } = useDocumentTemplateListQuery({
    variables: {
      ID: '0b3756c6-e0f9-4b11-b411-1690cacf76a1',
    },
    fetchPolicy: 'no-cache',
  })

  const [BISharedGAlleryInsert, { loading: loadingSum }] =
    useBiSharedGAlleryInsertMutation()
  const {
    data: { BIUsersLisiting } = {
      BIUsersLisiting: null,
    },
  } = useBiUsersLisitingQuery({
    variables: {
      ID: folioInfo?.FolioNo ? (folioInfo?.ClosedBy || folioInfo?.CreatedBy) : JSON.parse(localStorage.getItem('LoggedInUser'))?.ID,
    },
  })
  //Multiple Tax and qurey
  const {
    data: { GetCurrentGovTax, GetServiceChargeTax } = {
      GetCurrentGovTax: [],
      GetServiceChargeTax: [],
    },
  } = useGetHotelLatestTaxQuery({
    variables: {
      HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
    },
    fetchPolicy: 'no-cache',
  })

  function getSum(total, num) {
    return total + num
  }
  const isSelectedItemGovTax = SelectedItem?.some(
    x => x?.TaxType === TaxType.GovTax
  )
  // console.log(isSelectedItemGovTax, '????/')
  const { ReceiptTemplate, PaymentDetails, AdvancePaymentDetails } = useReceipt(
    {
      Type: stateInfo?.Page,
      stateInfo: stateInfo,
      HotelDetails: HotelDetails,
      BIUsersLisiting: BIUsersLisiting,
      GetCurrentGovTax: GetCurrentGovTax,
      GetServiceChargeTax: GetServiceChargeTax,
      GetTaxScheme: folioInfo?.TaxSchemeLedger || [],
    }
  )
  const { data: { DepositClass } = { DepositClass: null } } =
  useDepositClassQuery({
    variables: {
      ReversedID: !PaymentDetails?.Folio?.DepositLedger
        ? null
        : PaymentDetails?.Folio?.DepositLedger[0]?.ReversedID,
    },
    fetchPolicy: 'no-cache',
  })

  const documentType = (Data) => {
    if (Data?.Folio?.DocumentType !== null) {
      return Data?.Folio?.DocumentType
    } else {
      return Data?.Folio?.FolioNo
    }
  }

  const { InvoiceTemplate } = useInvoice({
    type: type,
    stateInfo: stateInfo,
    HotelDetails: HotelDetails,
    FolioDetails: folioInfo,
    BIUsersLisiting: BIUsersLisiting,
    GetCurrentGovTax: GetCurrentGovTax,
    GetServiceChargeTax: GetServiceChargeTax,
    FolioDetailsWithIDs : '',
    DebtorFolioInfo:DebtorFolioInfo,
    AdvancePaymentDetails:AdvancePaymentDetails,
    DepositClass:DepositClass
  })
  const ShareReceiptButton = {
    title: `${
      JSON.parse(localStorage.getItem('Hotel'))?.HotelName
    } has shared you a Receipt-${
      PaymentDetails
        ? documentType(PaymentDetails)?.slice(0, 2) === 'RF' ||
          documentType(PaymentDetails)?.slice(0, 1) === 'D'
          ? PaymentDetails?.Folio?.FolioNo
          : PaymentDetails?.ReceiptNo
        : documentType(AdvancePaymentDetails)?.slice(0, 2) === 'RF' ||
          documentType(AdvancePaymentDetails)?.slice(0, 1) === 'D'
        ? AdvancePaymentDetails?.Folio?.FolioNo
        : AdvancePaymentDetails?.ReceiptNo
    } please click`,
    url: imgUrl,
    img: `Click Here`,
  }

  let govtaxx = isSelectedItemGovTax
  ? 
      SelectedItem?.filter(x => x.TaxType !== 'StateFund')
        ?.filter(x => (x?.TaxType ? x?.TaxType === TaxType.GovTax : null))
        ?.map(x => x?.TaxAmount || null)
        .reduce((a, b) => a + b, 0)
    
  : 
      SelectedItem?.filter(x => x.TaxType !== 'StateFund')
        ?.map(x => {
          if (x?.TaxAmount) return x?.TaxAmount
          return 0
        })
        .reduce((a, b) => a + b, 0)
    

  console.log(govtaxx, 'govtaxx')

  const onSubmit = blob => {
    BISharedGAlleryInsert({
      variables: {
        HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
        AttachmentInput: blob,
      },
    }).then(i => {
      i.data.BISharedGAlleryInsert
        ? setimgUrl(i.data.BISharedGAlleryInsert.Gallery.ImageURL)
        : console.log('false')
    })
  }




  
     const iscityledgerdocs = () => {
      if(folioInfo?.FolioNo !== null){
        if (folioInfo?.DocumentType !== null){
          if((folioInfo?.DocumentType?.startsWith('CN') ||  folioInfo?.DocumentType?.startsWith('DN') || 
            folioInfo?.DocumentType?.startsWith('OR') || folioInfo?.DocumentType?.startsWith('RF') ) && 
            OutGoingDocTemplate?.Folio !== null
            ){
              return true;
            } 
            else {
              return false
            }
        } else {
          if((folioInfo?.FolioNo?.startsWith('CN') ||  folioInfo?.FolioNo?.startsWith('DN') || 
            folioInfo?.FolioNo?.startsWith('OR') || folioInfo?.FolioNo?.startsWith('RF') ) && 
            OutGoingDocTemplate?.Folio !== null )
            {
              return true;
            } 
            else {
              return false
            }
        }
      }
        else{
          return false
        }
      
      
        }

  const isinvoice = () => {
  
    if((folioInfo?.DocumentType === null && folioInfo?.FolioNo?.startsWith('INV')) ||
      (folioInfo?.DocumentType !== null && folioInfo?.DocumentType?.startsWith('INV'))
    ) {
      return true;
    } else if (
      (folioInfo?.DocumentType === null &&
        (folioInfo?.FolioNo?.startsWith('CN') || folioInfo?.FolioNo?.startsWith('DN') || 
        folioInfo?.FolioNo?.startsWith('OR') || folioInfo?.FolioNo?.startsWith('RF'))) ||
      (folioInfo?.DocumentType !== null &&
        (folioInfo?.DocumentType?.startsWith('CN') || folioInfo?.DocumentType?.startsWith('DN') || 
        folioInfo?.DocumentType?.startsWith('OR') || folioInfo?.DocumentType?.startsWith('RF')))
    ){
      return true;
    } else {
      return false
    }
  }

  
  let newitem2: string = ``

  let TotalAmount = isNonGuestFolio
  ? NonGuestFolioTotal !== undefined
    ? amtStr(NonGuestFolioTotal)
    : 0
  : folioInfo === null
  ? amtStr(stateInfo?.Total)
  : stateInfo?.Page === 'PaymentFolio' &&
    SelectedItem?.filter(y => y?.PaymentClass === 'Refund')?.length > 0
  ? amtStr(
      SelectedItem?.filter(y => y?.PaymentClass === 'Refund')
        ?.map(x => x?.Amount)
        ?.reduce((a, b) => a + b, 0)
    )
  : amtStr(folioInfo?.FolioAmount)

  console.log(TotalAmount,'TotalAmount')

  let totalpayment = (folioInfo?.Payment?.filter(t => t.Amount > 0 && t.PaymentType !== "CityLedger").reduce((acc, curr) => {
    return acc + curr.Amount
  }, 0) || 0)*-1

  let totalpaymentcityeldger = (folioInfo?.Payment?.filter(t => t.PaymentType === "CityLedger").reduce((acc, curr) => {
    return acc + (curr?.PaymentClass === 'Refund' ? -curr.Amount : curr.Amount)
  }, 0) || 0) 

  let totalpaymentall = (folioInfo?.Payment?.reduce((acc, curr) => {
    return acc + curr.Amount
  }, 0) || 0)

console.log(folioInfo?.Payment?.filter(t => t.PaymentType === "CityLedger"), 'i want to die')
console.log(totalpaymentcityeldger,'totalpaymentcityeldger2')
//  let totalpaymentcityeldger =  totalpaymentcityeldger2 < 0 ? totalpaymentcityeldger2*-1 : totalpaymentcityeldger2

  if (folioInfo?.Payment !== null) {
    folioInfo?.Payment?.sort((a, b) => {
      if (new Date(a?.PaymentDate) !== new Date(b?.PaymentDate)) {
        return +new Date(b?.PaymentDate) - +new Date(a?.PaymentDate)
      }
    }).filter(x => OutGoingDocTemplate !== null
      ?  x.PaymentType !== "CityLedger" : x).map((x, y) => {
      let item = `
        <tr  
        class="room-detail" 
        style="
          font-family: 'Poppins';
          -webkit-print-color-adjust: exact;
          -webkit-text-size-adjust:none;
          border: 0px solid #fff;
          background-color: #fff;
          color: #2c2c2c;
          font-size: 10px;
          line-height: 15px;
          text-align: center;">
          <td style="text-align: center; font-family:'Poppins';">${
            x
              ? dateFormat(x?.PaymentDate, 'dd/mm/yyyy')
              : dateFormat(AdvancePaymentDetails?.PaymentDate, 'dd/mm/yyyy')
          }</td>
          <td style="text-align: left; font-family:'Poppins';">${
            isNonGuestFolio
              ? `${x?.PaymentType === "BankTT" ? "Bank TT" : x?.PaymentType} Payment`
              : x
              ? folioInfo?.FolioNo?.slice(0, 2) === 'RF'
                ? `${x?.PaymentClass} - ${
                    DepositClass?.DepositClass === 'SecurityDeposit'
                      ? 'Security Deposit'
                      : 'Key Card Deposit'
                  }`
                : folioInfo?.FolioNo?.slice(0, 1) === 'D' &&
                  folioInfo?.FolioNo.slice(0, 2) !== 'DN'
                ? folioInfo?.DepositLedger &&
                  folioInfo?.DepositLedger[0]?.DepositClass ===
                    'SecurityDeposit'
                  ? 'Security Deposit'
                  : 'Key Card Deposit'
                : x?.PaymentClass === 'Refund'
                ? `Refund by ${x?.PaymentType === "BankTT" ? "Bank TT" : x?.PaymentType}`
                : x?.PaymentType === "BankTT" ? "Bank TT" : x?.PaymentType === 'CityLedger'
                ? // ? `Charge to City Ledger12 - ${PaymentDetails?.Registration?.Booking?.DebtorAccount}`
                  `Charge to City Ledger - ${x?.DebtorAccount}`
              // commented for bell action ***   : `Advance Payment -${x?.PaymentType === "BankTT" ? "Bank TT" : x?.PaymentType}`
              // : AdvancePaymentDetails?.Booking?.DebtorAccount === null
              // ? `${AdvancePaymentDetails?.PaymentType === "BankTT" ? "Bank TT" : x?.PaymentType} Payment`
                : `Advance Payment -${x?.PaymentType}`
              : AdvancePaymentDetails?.Booking?.DebtorAccount === null ||
                AdvancePaymentDetails?.PaymentType !== 'CityLedger'
              ? `${AdvancePaymentDetails?.PaymentType} Payment`
              : `Charge to City Ledger - ${AdvancePaymentDetails?.Booking?.DebtorAccount}`
          }</td>
          <td style="text-align: center;   font-family: 'Poppins';">
          </td>
   
          <td style="text-align: left;  font-family: 'Poppins'; padding-left:0px;">${x ? x?.ReceiptNo : ''}</td>
     
          <td style="text-align: right; padding-right: 2%;   font-family: 'Poppins';">
          ${
          x ? isnegativeamount(Number(x?.Amount),x)
          :isnegativeamount(AdvancePaymentDetails?.Amount,x)
          }</td>
    </tr>`

      newitem2 = `` + newitem2.concat(item)
    })
  }
  // console.log(newitem2, 'newitem2')
  // console.log(stateInfo, 'stateInfo')

  // console.log('HotelXFolio', HotelXFolio)
  // console.log('NewItem', EachFolioItem)
  console.log('FolioDetails', folioInfo)
  console.log('SelectedItem', SelectedItem)
  console.log(
    'tak tau',
    stateInfo?.Page === 'PaymentFolio' &&
      SelectedItem?.filter(y => y?.PaymentClass === 'Refund')?.length > 0
      ? SelectedItem?.filter(y => y?.PaymentClass === 'Refund')
      : SelectedItem?.filter(z => z?.PaymentClass !== 'Refund')
  )

  // console.log('NewItem2', newitem2)

  console.log(newitem2, 'newitem2')
  console.log(stateInfo, 'stateInfo')

  const subAmount = (SelectedItem?.reduce((acc, curr) => {
    return (curr.BaseAmount || curr.TaxAmount)  ? acc + (curr.BaseAmount || curr.TaxAmount) : acc - (curr?.PaymentClass === 'Refund' ? (-curr.Amount || 0) : (curr.Amount || 0))
  }, 0) || 0)

  const amountTotal = (SelectedItem?.reduce((acc, curr) => {
    return (curr.TrxAmount || curr.TaxAmount) 
      ? acc + (curr.TrxAmount || curr.TaxAmount) 
      : acc - (curr?.PaymentClass === 'Refund' ? (-curr.Amount || 0) : (curr.Amount || 0))
  }, 0) || 0)
  
  //  +(stateInfo?.Folio?.reduce((acc, curr) => {
  //   return acc + curr.TrxAmount
  // }, 0) || 0) 
console.log(Number(TotalAmount) ,Number(totalpayment),'test')

const outstandingbalanceFunc = () =>{
  let result = ''

  if(iscityledgerdocs () === true || isinvoice() === false )
  {
    result = ''
  }

  else{
result = outstandingbalancetemplate
  }
  return result
}
const outstandingbalancetemplate = `	  <div style="width: 100%; display: flex; margin-top: 10px;">
                        
<div style="width: 50%;  margin: 4px; padding: 6px; ">

</div>

<div style="width: 50%; display: block; padding-left: 15%;padding-right: 6px;">
       
       <table width="100%" border="0" cellspacing="0" cellpadding="0"  style="margin-bottom: 10px; font-family: 'Poppins';
   -webkit-print-color-adjust: exact;
   -webkit-text-size-adjust:none;" >

<tr class="" style="      background-color: #fff;
           color: #2c2c2c;
           font-size: 10px;
           line-height: 15px;
           ;
           ">
               <td style="
               font-weight: 800; padding-left: 0px; font-family:'Poppins';">OUTSTANDING BALANCE</td>
               <td style="
               font-weight: 800; font-family:'Poppins';  item-align:right; text-align: right; padding-left: 5px;
               color:#2c2c2c
               ">${(isnegativeamount(Number(totalpaymentcityeldger),''))}</td>
<td style="width:4%;
               "></td>
           </tr>
       </table>
</div>

</div>`
//maya only <3
const agentname = () =>{
  let result  = ''
  
  if(OutGoingDocTemplate !== null && DebtorFolioInfo !== null){
  
      result = ``
 
  }
  else{
    result = !folioInfo?.Registration?.Booking?.Debtor?.DebtorAccount
      ? ''
      : `Company/Travelling Agent Name:${folioInfo?.Registration?.Booking?.Debtor?.Name}` 
  }
      
      return result;
        
    }
let address =   
folioInfo?.BeneficiaryAddress 

  console.log(SelectedItem,subAmount,HotelDetails,'SelectedItem')
  console.log(HotelDetails,'Sele')
  const conditionSub = subAmount === 0 ? folioInfo?.FolioAmount : subAmount

  const eInvoiceQr = () => {
    const condition = FolioDetails?.is_einvoice === true && 
      HotelDetails?.Company?.isEInvIntegrated === true &&
      dateFormat(FolioDetails?.FolioDate, 'yyyy-mm-dd') >= dateFormat(HotelDetails?.Company?.eInvStartDate, 'yyyy-mm-dd') &&
      dateFormat(FolioDetails?.FolioDate, 'yyyy-mm-dd') <= dateFormat(HotelDetails?.Company?.eInvEndDate, 'yyyy-mm-dd') &&
      FolioDetails?.ClosedDate !== null && FolioDetails?.QrCodeUrl !== null

    const result = condition === true 
      ? DebtorFolioInfo === null 
        ? `<td class="qr-code" rowspan="3"; style="width:100%; text-align:right; justify-content:end; position: relative; vertical-align: center">
            <span style="display: flex; justify-content: center; text-align: center; padding-bottom: 4px">
              <img 
                src="${FolioDetails?.QrCodeUrl}"
                alt="QR Code" 
                style="width: 85px; display: inline-block;"
              >
            </span>
            <div style="width: 100%; right: 0; line-height: 1">
              <span style="font-size:9px; display: flex; justify-content: center; text-align: center;">
                Scan within 72 hours if
              </span>
              <span style="font-size:9px; display: flex; justify-content: center; text-align: center;">
                e-Invoice is required
              </span>
            </div>
          </td>`
        : `<td style="font-size:10px; font-weight: 400; line-height: 18px; width:35%;"></td>
            <td class="qr-code" rowspan="3" colspan="5"; style="width:100%; text-align:right; justify-content:end; position: relative; vertical-align: center">
              <span style="display: flex; justify-content: center; text-align: center; padding-bottom: 4px">
                <img 
                  src="${FolioDetails?.QrCodeUrl}"
                  alt="QR Code" 
                  style="width: 85px; display: inline-block;"
                >
              </span>
              <div style="width: 100%; right: 0; line-height: 1">
                <span style="font-size:9px; display: flex; justify-content: center; text-align: center;">
                  Scan within 72 hours if
                </span>
                <span style="font-size:9px; display: flex; justify-content: center; text-align: center;">
                  e-Invoice is required
                </span>
              </div>
          </td>`
      : ``
    return result;
  }

  const TourismTaxType = TourismTax > 0
    ? `<td style="font-weight: 300; padding-left: 0px;"></td>
       <td style="font-weight: 300;  item-align:right;text-align: right;"></td>`
    : ''
  
  const TaxTypeInput = SelectedItem?.length > 0
    ? stateInfo?.Page === 'PaymentFolio' &&
      SelectedItem?.filter(y => y?.PaymentClass === 'Refund')?.length > 0
      ? ''
      : !folioInfo?.TaxLedger
        ? ''
        : Heritage === 0 && Levy === 0 && StateFund === 0
          ? ''
          : Heritage > 0
            ? `<td style="font-weight: 300; padding-left: 0px;">
                Heritage Tax
               </td>
               <td style="font-weight: 300;  item-align:right;text-align: right;">
                ${amtStr(Heritage)}
               </td>`
            : Levy > 0
              ? `<td style="font-weight: 300; padding-left: 0px;">Levy Tax</td>
                 <td style="font-weight: 300; item-align:right; text-align: right;">
                  ${amtStr(Levy)}
                 </td>`
              : ''
    : ''

  const AmountItem = `
    <div style="width: 100%; display: flex; margin-top: 10px;">
      <div style="width: 50%;  margin: 4px; padding: 6px; "> </div>
      <div style="width: 50%; display: block; padding-left: 15%;padding-right: 6px;">
        <table 
          width="100%" border="0" cellspacing="0" cellpadding="0" 
          style="margin-bottom: 10px; font-family: 'Poppins';
            -webkit-print-color-adjust: exact;
            -webkit-text-size-adjust:none;"
        >
          <tr 
            class="" 
            style="background-color: #fff;
              color: #2c2c2c;
              font-size: 10px;
              line-height: 15px;"
          >
            <td style="font-weight: 300; padding-left: 0px;">
              Sub Total
            </td>
            <td style="font-weight: 300; item-align:right; text-align: right;">
              ${isnegativeamount(conditionSub,'')}
            </td>
            <td style="width:4%;"></td>
          </tr>
          <tr 
            class="" 
            style=" font-family: 'Poppins';
              -webkit-print-color-adjust: exact;
              -webkit-text-size-adjust:none; background-color: #fff;
              color: #2c2c2c;
              font-size: 10px;
              line-height: 15px;"
          ></tr>
          <tr 
            class="" 
            style="background-color: #fff;
              color: #2c2c2c;
              font-size: 10px;
              line-height: 15px;"
          >
            ${OutGoingDocTemplate?.Folio? HotelMayaTaxItem || '' : data || '' }
          </tr>
          <tr 
            class="" 
            style="background-color: #fff;
              color: #2c2c2c;
              font-size: 10px;
              line-height: 15px;"
          >
            ${TourismTaxType}
          </tr>
          <tr 
            class="" 
            style="background-color: #fff;
              color: #2c2c2c;
              font-size: 10px;
              line-height: 15px;"
          >
            ${TaxTypeInput}
          </tr>
          <tr 
            class="" 
            style="background-color: #fff;
              color: #2c2c2c;
              font-size: 10px;
              line-height: 15px;"
          >
            <td style="font-weight: 800; padding-left: 0px;">
              TOTAL
            </td>
            <td style="font-weight: 800;  item-align:right; text-align: right; color:#2c2c2c">
              ${isnegativeamount((Number(amountTotal)),'')}
            </td>
            <td style="width:4%;"></td>
          </tr>
        </table>
      </div>
    </div>`

  const eInvoiceUniqueID = () => {
    const isCondition = FolioDetails?.is_einvoice === true && 
      HotelDetails?.Company?.isEInvIntegrated === true &&
      dateFormat(FolioDetails?.FolioDate, 'yyyy-mm-dd') >= dateFormat(HotelDetails?.Company?.eInvStartDate, 'yyyy-mm-dd') &&
      dateFormat(FolioDetails?.FolioDate, 'yyyy-mm-dd') <= dateFormat(HotelDetails?.Company?.eInvEndDate, 'yyyy-mm-dd')
    
    const resUniqueID = isCondition
        ? 'UUID : ' + (FolioDetails?.einvoice_uniqueID !== '' ? FolioDetails?.einvoice_uniqueID : '-')
        : ''
    return resUniqueID
  }

  var mapObj: any = {
    //Hotel
    '!!HotelName!!':` ${HotelDetails?.Company?.name} (${HotelDetails?.Company?.companyRegNo})`,
    '!!ImperialLogo!!': HotelDetails?.ID === 'f28c4f3e-d68d-4f7b-a963-d44c97b3346c' ? ImperialLogoKuching : ImperialLogo,
    '!!HotelEmail!!': !HotelDetails?.['Contact']
      ? ''
      : !HotelDetails?.['Contact']?.['BusinessEmail']
      ? ''
      : HotelDetails?.['Contact']?.['BusinessEmail'],
      '!!SSTRegNo!!': !HotelDetails?.Company?.['sstNo'] || HotelDetails?.Company?.['sstNo'] === 'NA' ? '' : '(SST Reg No: ' + HotelDetails?.Company['sstNo'] + ')',
      '!!TTXRegNo!!': !HotelDetails?.Company?.['ttxNo'] || HotelDetails?.Company?.['ttxNo'] === 'NA' ? '' : '(TTX Reg No: ' + HotelDetails?.Company['ttxNo'] + ')',
      '!!TINNo!!':
        !HotelDetails?.Company?.['tin'] || !HotelDetails?.Company?.['isEInvIntegrated']
          ? ''
          : differenceInDays(
              new Date(
                folioInfo?.ClosedDate 
                  ? dateFormat(folioInfo?.ClosedDate, 'dd/mm/yyyy') 
                  : folioInfo?.FolioNo ? dateFormat(folioInfo?.FolioDate, 'dd/mm/yyyy') : dateFormat(new Date(), 'dd/mm/yyyy')
              ),
              new Date(dateFormat(HotelDetails?.Company?.['eInvStartDate'], 'yyyy-mm-dd'))
            ) < 0
            ? ''
            : '(TIN No: ' + HotelDetails?.Company['tin'] + ')',
    '!!HotelMobileNo!!': !HotelDetails?.['Contact']?.['PhoneNo']
      ? ''
      : HotelDetails?.['Contact']?.['PhoneNo'],
    '!!HotelFaxNo!!': HotelDetails?.Contact.FaxNo || '',
    '!!HotelPhone!!': HotelDetails?.Contact.MobileNo || '',
    '!!HotelCustomAddress!!':
      ` <p style="color:#2c2c2c">
      ${HotelDetails?.['HotelAddress']?.['address']
        .concat(' ')
        .concat(HotelDetails?.['HotelAddress']?.['postCode'])}
      <br> ${HotelDetails?.['HotelAddress']?.['state']}${' '}${
        HotelDetails?.['HotelAddress']?.['country']
      }</p>` || '',
    '!!HotelWebsite!!': !HotelDetails?.['Contact']?.['WebUrl']
      ? ''
      : HotelDetails?.['Contact']?.['WebUrl'],
    '!!HotelAddress!!': HotelDetails?.['HotelAddress']
      ? AddressConverter({
          address: HotelDetails?.['HotelAddress']?.['address'],
          state: HotelDetails?.['HotelAddress']?.['state'],
          postCode: HotelDetails?.['HotelAddress']?.['postCode'],
          city: HotelDetails?.['HotelAddress']?.['city'],
          country: HotelDetails?.['HotelAddress']?.['country'],
        })
      : '',

    '!!FolioNo!!': folioInfo?.FolioNo || '',

    '!!FOLIO!!': folioInfo?.FolioNo ? 'INVOICE' : 'FOLIO',

    //Header top
    '!!CashierName!!':
      BIUsersLisiting?.name ||
      JSON.parse(localStorage.getItem('LoggedInUser'))?.name,
    '!!Date!!': folioInfo?.ClosedDate 
      ? dateFormat(folioInfo?.ClosedDate, 'dd/mm/yyyy') 
      : folioInfo?.FolioNo ? dateFormat(folioInfo?.FolioDate, 'dd/mm/yyyy') : dateFormat(new Date(), 'dd/mm/yyyy'),
    '!!RoomNo!!':  OutGoingDocTemplate !== null ? 'N/A' : '',
    '!!BeneficiaryName!!': DebtorFolioInfo?.Name || '' ,
    

    '!!AgentName!!': agentname(),

    '!!GuestName!!':  OutGoingDocTemplate !== null &&  DebtorFolioInfo !== null ? '' : NonGuestFolioTitle || '',

    '!!GuestMobileNo!!':  OutGoingDocTemplate !== null ? 'N/A' : '',

    '!!GuestEmail!!':  OutGoingDocTemplate !== null ? 'N/A' : '',

    '!!GuestAddress!!':
    // OutGoingDocTemplate !== null ? 
    DebtorFolioInfo ? 
    AddressConverter({
      address:DebtorFolioInfo.Contact?.Address?.address,
      address2: DebtorFolioInfo?.Contact?.Address?.address2,
      city: DebtorFolioInfo?.Contact?.Address?.city,
      country: DebtorFolioInfo?.Contact?.Address?.country,
      postCode: DebtorFolioInfo?.Contact?.Address?.postCode,
      state: DebtorFolioInfo?.Contact?.Address?.state,
    })
    // : address || ''
    : address || '',

    '!!IdentityCard!!': OutGoingDocTemplate !== null ? 'N/A' : '',

    '!!ArrivalDate!!': OutGoingDocTemplate !== null ? 'N/A' : '',

    '!!ArrivalTime!!':  '',

    '!!DepartureDate!!': OutGoingDocTemplate !== null ? 'N/A' : '',

    '!!DepartureTime!!': '',

    '!!BookingNo!!': OutGoingDocTemplate !== null ? 'N/A' : '',
        //custonewitem2mtd 
        '!!ICtd!!' : DebtorFolioInfo === null ? `  <td style="font-size:10px; font-weight: 400;   line-height: 18px; width:30%;">
        IC &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: ${OutGoingDocTemplate !== null ? 'N/A' : ''}
        </td>` : '',
        '!!phonetd!!' :  DebtorFolioInfo === null  ? `  <td style="font-size:10px; font-weight: 400;  line-height: 18px; width:30%; vertical-align:top">
        Phone : ${OutGoingDocTemplate !== null ? 'N/A' : ''}
       </td>`: '',
        '!!emailtd!!' : DebtorFolioInfo === null  ? `<td style="font-size:10px; font-weight: 400; width:35%; vertical-align:top">
        Email &nbsp;&nbsp;: ${OutGoingDocTemplate !== null ? 'N/A' : ''}
  </td>`: '',
    //description
     '!!SubTotal!!': isnegativeamount(Number(chargesData.SubTotal.toFixed(2)), ''),
    // '!!SubTotal!!' : `(${amtStr(chargesData.SubTotal.toFixed(2))})`, // new changes
    '!!NewItem!!': EachFolioItem,
    '!!NewItem2!!': isinvoice() === true ? newitem2 : '',
    '!!ServiceCharge!!': GetServiceChargeTax['Value'],
    // '!!TotalServiceCharge!!': amtStr(
    //   SelectedItem?.map(x => x?.ServiceCharge || null).reduce(
    //     (a, b) => a + b,
    //     0
    //   )
    // ),
    '!!TotalServiceCharge!!': amtStr(chargesData.ServiceCharge.toFixed(2)), // new changes
    '!!Tax!!': GetCurrentGovTax['Value'],
    // '!!TotalGovTax!!': isSelectedItemGovTax
    //   ? amtStr(
    //       SelectedItem?.filter(x => x.TaxType !== 'StateFund')
    //         ?.filter(x => (x?.TaxType ? x?.TaxType === TaxType.GovTax : null))
    //         ?.map(x => x?.TaxAmount || null)
    //         .reduce((a, b) => a + b, 0)
    //     )
    //   : amtStr(
    //       SelectedItem?.filter(x => x.TaxType !== 'StateFund')
    //         ?.map(x => {
    //           if (x?.TaxAmount) return x?.TaxAmount
    //           return 0
    //         })
    //         .reduce((a, b) => a + b, 0)
    //     ),
    '!!TotalGovTax!!': amtStr(chargesData.ServiceTax.toFixed(2)), // new changes,
    '!!QtyHeader!!': `<th style="font-weight: 500;  width:10%;">Qty</th>`,
    '!!TotalTourismTax!!': SelectedItem?.filter(
      y => y?.PaymentClass === 'Refund'
    )
      ? amtStr(0)
      : amtStr(tourismTaxAmount),

    '!!TaxType!!':
      SelectedItem?.length > 0
        ? stateInfo?.Page === 'PaymentFolio' &&
          SelectedItem?.filter(y => y?.PaymentClass === 'Refund')?.length > 0
          ? ''
          : !folioInfo?.TaxLedger
          ? ''
          : Heritage === 0 && Levy === 0 && StateFund === 0
          ? ''
          : Heritage > 0
          ? `<td style="
font-weight: 300; padding-left: 0px;">Heritage Tax</td>
   <td style="
   font-weight: 300;  item-align:right;text-align: right;">${amtStr(
     Heritage
   )}</td>
`
          : Levy > 0
          ? `<td style="
font-weight: 300; padding-left: 0px;">Levy Tax</td>
   <td style="
   font-weight: 300; item-align:right; text-align: right;">${amtStr(Levy)}</td>
`
          : ''
        : '',

    '!!TourismTaxType!!':
      TourismTax > 0
        ? `<td style="
    font-weight: 300; padding-left: 0px;"></td>
       <td style="
       font-weight: 300;  item-align:right;text-align: right;"></td>`
        : '',

    '!!TaxTypeAmount!!': amtStr(
      folioInfo?.TaxLedger?.map(x => x?.TaxAmount).reduce((a, b) => a + b, 0)
    ),
    '!!TaxSchemeItem!!': OutGoingDocTemplate?.Folio ? HotelMayaTaxItem || '' : data || '',
    // '!!TotalAmount!!': folioInfo?.FolioNo ? isnegativeamount(NonGuestFolioTotal,'') : isnegativeamount((subAmount)
    //   + (SelectedItem?.map(x => x?.ServiceCharge || null).reduce((a, b) => a + b,0))
    //   + (govtaxx || 0)
    //   + (TourismTax || 0) + (Levy || 0) + (Heritage || 0) + (StateFund || 0)
    //     ,''),
    '!!TotalAmount!!':isnegativeamount(Number (chargesData.Total.toFixed(2)), ''), // new changes
    '!!OutStandingBalance!!': outstandingbalanceFunc()
    ,
    '!!InvoiceRemark!!': HotelDetails?.DocumentRemark?.replace(/<p/g, '<div')?.replace(/<\/p>/g, '</div>') || '',
    '!!BankDetail!!' : isnonguestandsuspend() === true ? `
 
     
    <div style="font-size: 10px; padding-top:25px; padding-bottom:50px;  ">
        <table border="1" cellspacing="0" cellpadding="0" style=";width: 40%;text-alignment:center; margin-left: auto;
                                        margin-right: auto;">
          <tr>
            <td>
              <table border="0" cellpadding=0 cellspacing=0>
                <tr>
                  <td colspan="3" style="padding-left:15px;">Our bank details as below :</td>
                </tr>
                <tr>
                  <td style="width:5%; padding-left:15px;">Beneficiary</td>
                  <td style="width:5px;">:</td>
                  <td style="width:12%;">SDB Properties Sdn Bhd</td>
                </tr>
                <tr>
                  <td style="width:5%;padding-left:15px;">Bank Name</td>
                  <td style="width:5px;">:</td>
                  <td style="width:12%;">Public Bank Berhad</td>
                </tr>
                <tr>
                  <td style="width:5%;padding-left:15px;">Bank Address</td>
                  <td style="width:5px;">:</td>
                  <td style="width:12%;">Menara Public Bank </td>
                </tr>
                <tr>
                  <td style="width:5%;padding-left:15px;"></td>
                  <td style="width:5px;"></td>
                  <td style="width:12%;"> 146, Jalan Ampang </td>
                </tr>
                <tr>
                  <td style="width:5%;padding-left:15px;"></td>
                  <td style="width:5px;"></td>
                  <td style="width:12%;"> 50450 Kuala Lumpur,Malaysia </td>
                </tr>
                <tr>
                  <td style="width:5%;padding-left:15px;">Account No.</td>
                  <td style="width:5px;">:</td>
                  <td style="width:12%;"> 310-3935-218 </td>
                </tr>
                <tr>
                  <td style="width:5%;padding-left:15px;">Bank ID</td>
                  <td style="width:5px;">:</td>
                  <td style="width:12%;"> 190055-A </td>
                </tr>
                <tr>
                  <td style="width:5%;padding-left:15px;">Swift Code</td>
                  <td style="width:2%;">:</td>
                  <td style="width:12%;"> PBBEMYKL </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    ` : ``,
'!!IsSmfooter!!': isnonguestandsuspend() === true ? `` : 
`    	
<hr style="padding-right:10px;" />
<table width="100%" border="0" cellspacing="0" cellpadding="0" style="padding-top:10px; padding-bottom:10px;">
<td style="font-size:10px; font-weight: 400;   line-height: 15px; width:25%; text-align: justify; color:#2c2c2c"> * I agree that <span style="font-weight: bold;">i</span> am personally liable for the payment of the following statement and if the person, company or association indicated by me as being responsible for payment of the same does not do so, that my liability for such payment shall be joint and several with such person, company or association. </td>
<td style="font-size:10px; font-weight: 400;  line-height: 15px; width:20%; text-align: right;"></td>
<td style="font-size:10px; font-weight: 400;   line-height: 15px; width:25%; text-align: left; color:#2c2c2c">
<hr style="padding-right:10px;"> Guest Signature
</td>
</tr>
</table>`,
    '!!customheightfooter!!': isnonguestandsuspend() === true ? `10%` : `20%`, //beta
    '!!TotalRounding!!' : '',
    '!!customheightcontent!!':
      isnonguestandsuspend() === true ? `110px` : `220px`,
    '!!eInvoiceQR!!': eInvoiceQr(),
    '!!UID!!': eInvoiceUniqueID(),
    '!!ExternalOutlet!!': iseInvoice() === true ? externalOutlet : '',
    '!!AmountItem!!': selectedItemDesc?.length > 0 ? AmountItem: '',
  }

  var FormContent =  OutGoingDocTemplate !== null ? OutGoingDocTemplate?.Folio?.replace(
    /!!FOLIO!!|!!HotelImage!!|!!InvoiceRemark!!|!!customheightfooter!!|!!ICtd!!|!!phonetd!!|!!emailtd!!|!!OutStandingBalance!!|!!SubTotal!!|!!customheightcontent!!|!!IsSmfooter!!|!!BankDetail!!|!!HotelCustomAddress!!|!!HotelPhone!!|!!HotelFaxNo!!|!!QtyHeader!!|!!FolioNo!!|!!NewItem!!|!!NewItem2!!|!!TotalAmount!!|!!TotalTourismTax!!|!!TotalGovTax!!|!!TotalServiceCharge!!|!!TaxTypeAmount!!|!!TaxType!!|!!TourismTaxType!!|!!Tax!!|!!ServiceCharge!!|!!AgentName!!|!!GuestAddress!!|!!IdentityCard!!|!!CashierName!!|!!SSTRegNo!!|!!TTXRegNo!!|!!TINNo!!|!!HotelWebsite!!|!!HotelMobileNo!!|!!HotelEmail!!|!!HotelAddress!!|!!BookingNo!!|!!DepartureTime!!|!!DepartureDate!!|!!GuestName!!|!!HotelName!!|!!Date!!|!!RoomNo!!|!!ArrivalTime!!|!!GuestMobileNo!!|!!ArrivalDate!!|!!GuestEmail!!|!!BeneficiaryName!!|!!BeneficiaryAddress!!|!!TotalRounding!!|!!TaxSchemeItem!!|!!eInvoiceQR!!|!!UID!!|!!ExternalOutlet!!|!!AmountItem!!|!!ImperialLogo!!/gi,
    function (matched) {
      // var x = mapObj[matched]
      // console.log(x)
      return mapObj[matched]
    }
  ) : HotelXFolio.replace(
    /!!FOLIO!!|!!HotelImage!!|!!InvoiceRemark!!|!!FolioNo!!|!!NewItem!!|!!SubTotal!!|!!NewItem2!!|!!TotalAmount!!|!!TotalTourismTax!!|!!TotalGovTax!!|!!TotalServiceCharge!!|!!TaxTypeAmount!!|!!TaxType!!|!!TourismTaxType!!|!!Tax!!|!!ServiceCharge!!|!!AgentName!!|!!GuestAddress!!|!!IdentityCard!!|!!CashierName!!|!!SSTRegNo!!|!!TTXRegNo!!|!!TINNo!!|!!HotelWebsite!!|!!HotelMobileNo!!|!!HotelEmail!!|!!HotelAddress!!|!!BookingNo!!|!!DepartureTime!!|!!DepartureDate!!|!!GuestName!!|!!HotelName!!|!!Date!!|!!RoomNo!!|!!ArrivalTime!!|!!GuestMobileNo!!|!!ArrivalDate!!|!!GuestEmail!!|!!BeneficiaryName!!|!!BeneficiaryAddress!!|!!TotalRounding!!|!!TaxSchemeItem!!|!!eInvoiceQR!!|!!UID!!|!!ExternalOutlet!!|!!AmountItem!!/gi,
    function (matched) {
      // var x = mapObj[matched]
      // console.log(x)
      return mapObj[matched]
    }
  )

  
// console.log(FormContent,'FormContent')
  const printForm = () => {
    var win = window?.open()
    win?.document?.write(
      FormContent,
      folioInfo?.FolioNo ? ReceiptTemplate : ''
    )
    win?.document?.close()
    setTimeout(() => {
      win?.print()
    }, 100)
  }
  const initialState: props = {
    DocSelectionDialog: false,
    ButtonType: '',
    DocSelected: '',
    DocList: [],
  }
  const reducer: Reducer<props, IAction> = (state, action) => {
    switch (action.type) {
      case 'Reset':
        return initialState

      default:
        return { ...state, [action.type]: action.payload }
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  // console.log(state)

  const paymentFolioSelection = type => {
    dispatch({
      type: 'DocSelectionDialog',
      payload: true,
    })
    dispatch({
      type: 'ButtonType',
      payload: type,
    })
    dispatch({
      type: 'DocList',
      payload: [
        {
          DocumentNo: folioInfo?.Payment?.filter(x => x.PaymentClass === 'Refund').length >
            0 && stateInfo?.Page === 'PaymentFolio'
            ? folioInfo?.Payment?.filter(x => x.PaymentClass === 'Refund')[0]
                ?.ReceiptNo
            : null,
          DocumentType: folioInfo?.Payment?.filter(x => x.PaymentClass === 'Refund').length >
          0 && stateInfo?.Page === 'PaymentFolio'
          ? folioInfo?.Payment?.filter(x => x.PaymentClass === 'Refund')[0]
              ?.DocumentType
          : null,
        },
        {
          DocumentNo: folioInfo?.FolioNo,
          DocumentType: folioInfo?.DocumentType
        },
        {
          DocumentNo: PaymentDetails?.ReceiptNo,
          DocumentType: PaymentDetails?.DocumentType
        }
      ],
    })
    dispatch({
      type: 'DocSelected',
      payload: folioInfo?.DocumentType === null 
        ? folioInfo?.FolioNo
        : folioInfo?.DocumentType,
    })
  }
  const docSelectionPrint = (docSelectionPrint) => {
    let Template;
    if (docSelectionPrint.length > 0) {
      Template = docSelectionPrint?.map((x, i) => 
        folioInfo?.Payment?.filter(x => x.PaymentClass === 'Refund').length > 0
          ? (x?.ID?.startsWith('HTRF') && x?.DocumentType === null) || (x?.DocumentType?.startsWith('HTRF') && x?.DocumentType !== null)
            ? FormContent
            : (x?.ID?.startsWith('INV') && x?.DocumentType === null) || (x?.DocumentType?.startsWith('INV') && x?.DocumentType !== null)
              ? InvoiceTemplate
              : ReceiptTemplate
          : (x?.ID?.startsWith('INV') && x?.DocumentType === null) || (x?.DocumentType?.startsWith('INV') && x?.DocumentType !== null)
            ? FormContent
            : ReceiptTemplate
      )

      var win = window?.open()
      win?.document?.write(Template.join('<div style="page-break-after: always;"></div>'))
      win?.document?.close()
      setTimeout(() => {
        win?.print()
      }, 100)
    }
  }
  //! Share
  const docSelectionShare = (docSelectionShare) => {
    const TemplateBlob = docSelectionShare?.map(x => {
      const blobReceipt = htmlConverter({
        FormContent,
        Name:
          'Receipt' + PaymentDetails
            ? documentType(PaymentDetails)?.slice(0, 2) === 'RF' ||
              documentType(PaymentDetails)?.slice(0, 1) === 'D'
              ? PaymentDetails?.Folio?.FolioNo
              : PaymentDetails?.ReceiptNo
            : documentType(AdvancePaymentDetails)?.slice(0, 2) === 'RF' ||
              documentType(AdvancePaymentDetails)?.slice(0, 1) === 'D'
            ? AdvancePaymentDetails?.Folio?.FolioNo
            : AdvancePaymentDetails?.ReceiptNo,
      })

      const checkDocType = (x?.ID?.startsWith('HTRF') && x?.DocumentType === null) || 
        (x?.DocumentType?.startsWith('HTRF') && x?.DocumentType !== null) ||
        (x?.ID?.startsWith('INV') && x?.DocumentType === null) || 
        (x?.DocumentType?.startsWith('INV') && x?.DocumentType !== null)

      const blobInvoice = htmlConverter({
        FormContent,
        Name: 'Folio' + folioInfo?.FolioNo,
      })

      return checkDocType
        ? blobInvoice
        : blobReceipt;
        
    })

    setSimple(true)
    onSubmit(TemplateBlob)
  }
  
  const defaultShare = () => {
    const blob = htmlConverter({
      FormContent,
      Name: 'Folio' + folioInfo?.FolioNo,
    })
    setSimple(true)
    onSubmit(blob)
  }

  return loading || loadingSum || loadingInsert ? (
    <Loading />
  ) : (
    <>
    <MainHeader
      mainBtn="back"
      onClick={() => {
        stateInfo?.mode || isNonGuestFolio || type === 'Checkout Adjustment'
          ? history.goBack()
          : history.push('/businessinsight/digitaldocument/folio', {
              StartDate: stateInfo.StartDate,
              EndDate: stateInfo.EndDate,
              name: stateInfo.name,
            })
      }}
      smTitle="HMS"
      title={JSON.parse(localStorage.getItem('Hotel'))?.HotelName}
      routeSegments={[
        { name: 'Digital Document' },
        { name: 'Digital Document' },
        { name: 'Folio', current: true },
      ]}
      // rightRouteSegments={[
      //   {
      //     name: `${
      //       FolioDetails !== null
      //         ? FolioDetails?.FolioNo
      //         : FolioDetailsWithIDs?.Booking?.BookingNo
      //     }`,
      //     current: true,
      //   },
      // ]}
    />


      <div
        style={{ fontSize: 12, padding: 5 }}
        dangerouslySetInnerHTML={{
          __html: FormContent,
        }}
      ></div>
      {/* {FolioDetails?.Payment?.filter(x => x.PaymentClass === 'Refund')
        .length > 0 && stateInfo?.Page === 'PaymentFolio' ? (
        <div
          className="m-t-15"
          style={{ fontSize: 12, padding: 5 }}
          dangerouslySetInnerHTML={{ __html: InvoiceTemplate }}
        />
      ) : null} */}
      {folioInfo?.Payment && folioInfo?.FolioNo ? (
        <div
          className="m-t-15"
          style={{ fontSize: 12, padding: 5 }}
          dangerouslySetInnerHTML={{ __html: ReceiptTemplate }}
        />
      ) : null}
 
    {stateInfo.Page === 'PaymentInfo' && state.DocSelected.startsWith('INV')
      ? ShareDialog({
          ShareTitle: ShareButton.title,
          Title: `Share ${type ? type : 'Folio'}--${folioInfo?.FolioNo}`,
          URL: ShareButton.url,
          setSimple: setSimple,
          simple: simple,
          EmailShare: emailShare,
          setSimpleEmail: setSendEmail,
        })
      : ShareDialog({
          ShareTitle: ShareReceiptButton.title,
          Title: `Share ${
            type
              ? type === TypesRoute.Refund
                ? 'Refund Voucher'
                : type
              : 'Receipt'
          }--${
            PaymentDetails
              ? documentType(PaymentDetails)?.slice(0, 2) === 'RF' ||
                documentType(PaymentDetails)?.slice(0, 1) === 'D'
                ? PaymentDetails?.Folio?.FolioNo
                : PaymentDetails?.ReceiptNo
              : documentType(AdvancePaymentDetails)?.slice(0, 2) === 'RF' ||
                documentType(AdvancePaymentDetails)?.slice(0, 1) === 'D'
              ? AdvancePaymentDetails?.Folio?.FolioNo
              : AdvancePaymentDetails?.ReceiptNo
          }`,
          URL: ShareReceiptButton.url,
          setSimple: setSimple,
          simple: simple,
          EmailShare: emailShare,
          setSimpleEmail: setSendEmail,
        })}
    {ShareDialog({
      ShareTitle: ShareButton.title,
      Title: `Share ${type ? type : 'Folio'}--${folioInfo?.FolioNo}`,
      URL: ShareButton.url,
      setSimple: setSimple,
      simple: simple,
      EmailShare: emailShare,
      setSimpleEmail: setSendEmail,
    })}
    <DocSelectionDialog
      state={state}
      dispatch={dispatch}
      docSelectionPrint={docSelectionPrint}
      docSelectionShare={docSelectionShare}
    />
      {/* {Einvoice ? <RefreshButton
 onClick={() => refetch()}  
               floatTotalAmt
              /> : null} */}
              
    <Footer
      options={[
        {
          name: 'Share',
          disabled: handlePermDisabled({
            hotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
            permEnum:
              HotelPermission.BusinessInsightDigitalDocumentFolioShare,
          }),

          onClick: () =>
          folioInfo?.FolioNo
              ? paymentFolioSelection('Share')
              : defaultShare(),
          color: 'primary',
        },
        {
          name: 'print',
          disabled: handlePermDisabled({
            hotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
            permEnum:
              HotelPermission.BusinessInsightDigitalDocumentFolioPrint,
          }),
          onClick: () =>
          folioInfo?.FolioNo
              ? paymentFolioSelection('Print')
              : printForm(),
          color: 'primary',
        },
      ]}
    />
    <SnackBarMsg open={openSnackBar} message={snackBarMessage} />
  </>
    
  )
 
}
