import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { Footer } from 'components/Footer/Footer'
import SnackBarMsg from 'components/SnackBar/SnackBarMsg'
import { usePermissionChecker } from 'containers/helper/hooks/usePermissionChecker'
import { useSnackBar } from 'containers/helper/hooks/useSnackBar'
import { IAction } from 'containers/helper/models'
import { amtStr } from 'containers/helper/numFormatter'
import { Filter } from 'containers/helper/SearchFilter'
import hotelmayalogo from '../../../assets/images/HotelMaya/HotelMayaSide.png'
import ShareDialog, { htmlConverter, PhoneNumDialog } from 'containers/helper/Share'
import {
  HotelPermission,
  TaxType,
  useBiSharedGAlleryInsertMutation,
  useBiUsersLisitingQuery,
  useDocumentTemplateListQuery,
  useEmailSendMutation,
  useFolioDetailsLazyQuery,
  useFolioDetailsWithIDsLazyQuery,
  useFolioHistoryListingQuery,
  useGetHotelLatestTaxQuery,
  useHotelDetailsQuery,
  useDepositClassQuery,
  usePaymentDetailsQuery,
  useOutGoingDocTemplateLazyQuery,
  useDebtorFolioInfoQuery
} from 'generated/graphql'
import React, { useEffect, useState, Reducer, useReducer } from 'react'
import { useHistory, useLocation } from 'react-router'
import { TypesRoute } from '../BusinessRoute'
import AddressConverter from './addressConverter'
import { DocSelectionDialog } from './DocSelectionDialog'
import { useReceipt } from './hooks/useReceipt'
import { useInvoice } from './hooks/useInvoice'
import { usePreventRefresh } from 'containers/helper/hooks/usePreventRefresh'
import { HotelXFolio } from './CustomizedDocument/Default/Folio'
import { BIDocumentMaya } from './CustomizedDocument/Maya/BIDocument'
import { BIDocumentPromenade } from './CustomizedDocument/Promenade/BIDocument'
import { BIDocumentPromenadeBintulu } from './CustomizedDocument/PromenadeBintulu/BIDocument'
import { OutgoingDoc } from 'containers/FrontDeskModule/OutgoingDoc/OutgoingDoc'
import { HotelXFolioHotelMaya } from './CustomizedDocument/Default/FolioHotelMaya'
import { HotelXReceipt } from './CustomizedDocument/Default/Receipt'
import { useGetTaxScheme } from 'containers/CommonSettingsModule/TaxScheme/hooks/useGetTaxScheme'
import RefreshButton from 'components/Button/RefreshButton'
import { BIDocumentImperial } from './CustomizedDocument/Imperial/BIDocument'
import { differenceInDays } from 'date-fns'
interface Props {
  mode?: string
  type?: string
}
interface Document {
  dialog?: boolean
  signatureUrl?: null | undefined
}
interface props {
  DocSelectionDialog?: boolean
  ButtonType?: string
  DocList?: any[]
  selectedarr?: any[]
  DocSelected?: string
}
export const BIDocument = (props: Props) => {
  const { handleKeyDown, handleRightClick } = usePreventRefresh()
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('contextmenu', handleRightClick)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('contextmenu', handleRightClick)
    }
  }, [])
  const dateFormat = require('dateformat')
  const { mode, type } = props
  const history = useHistory()
  const [simple, setSimple] = useState(false)
  const [imgUrl, setimgUrl] = useState(null)
  const location = useLocation()
  const { handlePermDisabled } = usePermissionChecker()
  const [emailShare, setEmailShare] = useState(false)
  const [sendEmail, setSendEmail] = useState(false)
  const [EmailAddress, setEmailAddress] = useState('')
  const { snackFunc, snackBarMessage, openSnackBar } = useSnackBar()
  const stateInfo: any = location.state

  const [openPhoneNum, setOpenPhoneNum] = useState(false);

  const [
    Details,
    {
      refetch,
      loading: Loadings,
      data: { FolioDetails } = {
        FolioDetails: null,
      },
    },
  ] = useFolioDetailsLazyQuery({
    variables: {
      ID: stateInfo?.FolioID || stateInfo?.Folio?.ID,
      HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
    },
    fetchPolicy: 'no-cache',
  })

  const iseInvoice = () => {
    const iseInvoice = JSON.parse(localStorage.getItem('Hotel'))?.Company?.isEInvIntegrated === true
    const startDate = JSON.parse(localStorage.getItem('Hotel'))?.Company?.eInvStartDate
    const endDate = JSON.parse(localStorage.getItem('Hotel'))?.Company?.eInvEndDate
    const effDate = FolioDetails?.FolioType === 'NonGuest' ? FolioDetails?.ClosedDate : FolioDetails?.FolioDate

    const eInvoice = iseInvoice && 
      (dateFormat(effDate, "yyyy-mm-dd") >= dateFormat(startDate, "yyyy-mm-dd")) &&
      (dateFormat(effDate, "yyyy-mm-dd") <= dateFormat(endDate, "yyyy-mm-dd"))

    return eInvoice === true ? true : false
  }

  const taxListing = () => {
    let taxList: any = []
    let roomTaxGrouping: any = []
    let billTaxGrouping: any = []

    if (FolioDetails?.RoomCharges !== undefined && FolioDetails?.BillLedger !== undefined) {

      if (FolioDetails?.RoomCharges !== null && stateInfo?.FolioType !== 'NonGuest') {
        roomTaxGrouping = [].concat(
          ...FolioDetails?.RoomCharges?.map(x => {
            if (x?.TaxRoomLedger) {
              return x?.TaxRoomLedger
            } else {
              return
            }
          })?.filter(y => y !== undefined)
        )
      }

      if (FolioDetails?.RoomChargesSuspend !== null && stateInfo?.FolioType === 'NonGuest') {
        roomTaxGrouping = [].concat(
          ...FolioDetails?.RoomCharges?.map(x => {
            if (x?.TaxRoomLedger) {
              return x?.TaxRoomLedger
            } else {
              return
            }
          })?.filter(y => y !== undefined)
        )
      }

      if (FolioDetails?.BillLedger !== null) {
        billTaxGrouping = iseInvoice() === true
        ? [].concat(
          ...FolioDetails?.BillLedger?.filter(
            f => f.IncidentalCharge?.isEInvIntegrated === true && 
            f.IncidentalCharge?.IsOutlet !== true && 
            f.IncidentalCharge?.IsEventInterface !== true
          )?.map(x => {
            if (x?.TaxBillLedger) {
              return x?.TaxBillLedger
            } else {
              return
            }
          })?.filter(y => y !== undefined)
        ) : [].concat(
          ...FolioDetails?.BillLedger?.map(x => {
            if (x?.TaxBillLedger) {
              return x?.TaxBillLedger
            } else {
              return
            }
          })?.filter(y => y !== undefined)
        )
      }

      const Listing = roomTaxGrouping.concat(billTaxGrouping)

      taxList = Listing?.reduce((acc, curr) => {
        const { TaxID, TaxInfo, TaxAmount, TaxRate } = curr

        const existing = acc.find((x) => x.TaxID === TaxID)

        if (existing) {
          existing.TaxAmount += TaxAmount
        } else {
          acc.push({TaxID, TaxRate, TaxInfo, TaxAmount})
        }

        return acc;
      }, [])
    }

    return taxList
  }
  const { data } = useGetTaxScheme({
    TaxOutput: stateInfo?.Tax || taxListing(), 
    Type: 'NonGuest',
  })
  
  const initialState: props = {
    DocSelectionDialog: false,
    ButtonType: '',
    DocSelected: '',
    DocList: [],
    selectedarr: [],
  }
  const reducer: Reducer<props, IAction> = (state, action) => {
    switch (action.type) {
      case 'Reset':
        return initialState

      default:
        return { ...state, [action.type]: action.payload }
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)

  const TransactionType = {
    Package: 'Package',
    Rate: 'Rate',
  }
  const { data: { HotelDetails } = { HotelDetails: null } } =
    useHotelDetailsQuery({
      variables: {
        HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
      },
      fetchPolicy: 'no-cache',
    })
  
  const {
    loading,
    data: { DocumentTemplateList } = { DocumentTemplateList: null },
  } = useDocumentTemplateListQuery({
    variables: {
      ID: '0b3756c6-e0f9-4b11-b411-1690cacf76a1',
    },
    fetchPolicy: 'no-cache',
  })


  const [
    Template,
    {
      loading: Loadingtemp,
      data: { OutGoingDocTemplate } = {
        OutGoingDocTemplate: null,
      },
    },
  ] = useOutGoingDocTemplateLazyQuery({
    variables: {
      HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
    },
    fetchPolicy: 'no-cache',
  })

  const [
    Details2,
    {
      loading: Loadings2,
      data: { FolioDetailsWithIDs } = {
        FolioDetailsWithIDs: null,
      },
    },
  ] = useFolioDetailsWithIDsLazyQuery({
    variables: {
      HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
      RegistrationID: stateInfo?.RegistrationID,
      LedgerIDs: {
        BillSchedulesSplit:
          stateInfo?.FolioDetail?.roomChargeArr
            ?.filter(x => x?.IsSelected === true)
            ?.filter(z => z?.Description?.includes('Split'))
            ?.map(y => y?.SplitGroup)?.length === 0
            ? 1
            : stateInfo?.FolioDetail?.roomChargeArr
                ?.filter(x => x?.IsSelected === true)
                ?.filter(z => z?.Description?.includes('Split'))
                ?.map(y => y?.SplitGroup),
        BillSchedulesDescription: stateInfo?.FolioDetail?.roomChargeArr
          ?.filter(x => x?.IsSelected === true)
          ?.map(y => y?.Description?.slice(14)),
        BillSchedules: stateInfo?.FolioDetail?.roomChargeArr
          ?.filter(x => x?.IsSelected === true)
          ?.map(y => y?.RegistrationID),
        BillSchedulesDate: stateInfo?.FolioDetail?.roomChargeArr
          ?.filter(x => x?.IsSelected === true)
          ?.map(y => y?.TrxDate),
        RoomLedgersSplit:
          stateInfo?.FolioDetail?.roomChargeArr
            ?.filter(x => x?.IsSelected === true)
            ?.filter(z => z?.Description?.includes('Split'))
            ?.map(y => y?.SplitGroup)?.length === 0
            ? 1
            : stateInfo?.FolioDetail?.roomChargeArr
                ?.filter(x => x?.IsSelected === true)
                ?.filter(z => z?.Description?.includes('Split'))
                ?.map(y => y?.SplitGroup),
        RoomLedgersDescription: stateInfo?.FolioDetail?.roomChargeArr
          ?.filter(x => x?.IsSelected === true)
          ?.map(y => y?.Description?.slice(14)),
        RoomLedgers: stateInfo?.FolioDetail?.roomChargeArr
          ?.filter(x => x?.IsSelected === true)
          ?.map(y => y?.RegistrationID),
        RoomLedgersDate: stateInfo?.FolioDetail?.roomChargeArr
          ?.filter(x => x?.IsSelected === true)
          ?.map(y => y?.TrxDate),
        BillLedgerIDs: stateInfo?.FolioDetail?.incidentalChargeArr
          ?.filter(x => x?.IsSelected === true)
          ?.map(y => y?.ID),
        TaxLedgerIDs: stateInfo?.FolioDetail?.taxLedgerArr
          ?.filter(x => x?.IsSelected === true)
          ?.map(y => y?.ID),
        PaymentIDs: stateInfo?.FolioDetail?.paymentArr
          ?.filter(x => x?.IsSelected === true)
          ?.map(y => y?.ID),
        AdvancePaymentIDs: stateInfo?.FolioDetail?.advPaymentArr
          ?.filter(x => x?.IsSelected === true)
          ?.map(y => y?.ID),
      },
    },
    fetchPolicy: 'no-cache',
  })

    // const BIcreatedByID = stateInfo?.Selecteditem[0]?.Registration?.Booking?.CreatedBy
    const [BISharedGAlleryInsert, { loading: loadingSum }] =
    useBiSharedGAlleryInsertMutation()
  const {
    data: { BIUsersLisiting } = {
      BIUsersLisiting: null,
    },
  } = useBiUsersLisitingQuery({
    variables: {
      ID: FolioDetails?.CreatedBy,
    },
  })
  //Multiple Tax and qurey
  const {
    data: { GetCurrentGovTax, GetServiceChargeTax } = {
      GetCurrentGovTax: [],
      GetServiceChargeTax: [],
    },
  } = useGetHotelLatestTaxQuery({
    variables: {
      HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
    },
    fetchPolicy: 'no-cache',
  })

  const {  data: { DebtorFolioInfo } = {     DebtorFolioInfo: null,  }, } =
  useDebtorFolioInfoQuery({
      variables: {
        HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
        DebtorAccount : FolioDetails?.Payment?.find(x => x?.DebtorAccount !== null && x.PaymentType === "CityLedger" && (x?.PaymentClass === 'Room' || x?.PaymentClass === 'Folio' || x?.PaymentClass === 'Advance')
          && x?.ReversedPaymentID === null)?.DebtorAccount || null
      },
      fetchPolicy: 'no-cache',
    })

    const { ReceiptTemplate, PaymentDetails, AdvancePaymentDetails } = useReceipt(
      {
        Type: stateInfo?.Page,
        stateInfo: stateInfo,
        HotelDetails: HotelDetails,
        BIUsersLisiting: BIUsersLisiting,
        GetCurrentGovTax: GetCurrentGovTax,
        GetServiceChargeTax: GetServiceChargeTax,
      }
    )
    const { data: { DepositClass } = { DepositClass: null } } =
      useDepositClassQuery({
        variables: {
          ReversedID: !PaymentDetails?.Folio?.DepositLedger
            ? null
            : PaymentDetails?.Folio?.DepositLedger[0]?.ReversedID,
        },
        fetchPolicy: 'no-cache',
      })
    const { InvoiceTemplate } = useInvoice({
      type: type,
      stateInfo: stateInfo,
      HotelDetails: HotelDetails,
      FolioDetails: FolioDetails,
      BIUsersLisiting: BIUsersLisiting,
      GetCurrentGovTax: GetCurrentGovTax,
      GetServiceChargeTax: GetServiceChargeTax,
      FolioDetailsWithIDs : FolioDetailsWithIDs,
      DebtorFolioInfo:DebtorFolioInfo,
      AdvancePaymentDetails:AdvancePaymentDetails,
      DepositClass:''
    })
  const Payment = FolioDetails?.Payment
    ? FolioDetails?.Payment?.filter(x => x.PaymentClass === 'Refund').length
    : null

  const Charge = FolioDetails?.Payment
    ? FolioDetails?.Payment?.filter(x => {
        return x.PaymentClass === 'Refund'
      }).length > 0
      ? FolioDetails.FolioNo
      : null
    : null

  // console.log(FolioDetailsWithIDs)

  const isNonGuestFolio = FolioDetails?.FolioType === 'NonGuest'
const Einvoice = FolioDetails?.QrCodeUrl === null && FolioDetails?.is_einvoice === true && iseInvoice()


  const NonGuestFolioTitle = FolioDetails?.NonGuestInfo?.Title
  const NonGuestFolioTotal =
    (FolioDetails?.RoomCharges?.reduce((acc, curr) => {
      return acc + curr.TrxAmount
    }, 0) || 0) +
    (FolioDetails?.BillLedger?.reduce((acc, curr) => {
      return acc + curr.TrxAmount
    }, 0) || 0)+
    (FolioDetails?.TaxLedger?.reduce((acc, curr) => {
      return acc + curr.TaxAmount
    }, 0) || 0)

  // const NonGuestPaymentTotal = FolioDetails?.Payment?.reduce((acc, curr) => {
  //   return acc + curr.Amount
  // }, 0)

  // const { data: { FolioDetailsWithIDs } = { FolioDetails: null } } =
  // useFolioDetailsWithIDsQuery({
  //     variables: {
  //     HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
  //     RegistrationID: stateInfo?.RegistrationID,
  //     LedgerIDs:{
  //       BillSchedules: stateInfo?.FolioDetail?.roomChargeArr?.filter(x=>x?.IsSelected === true)?.map(y=> y?.TrxDate),
  //       RoomLedgers: stateInfo?.FolioDetail?.roomChargeArr?.filter(x=>x?.IsSelected === true)?.map(y=> y?.TrxDate),
  //       BillLedgerIDs: stateInfo?.FolioDetail?.incidentalChargeArr?.filter(x=>x?.IsSelected === true)?.map(y=>y?.ID),
  //       TaxLedgerIDs: stateInfo?.FolioDetail?.taxLedgerArr?.filter(x=>x?.IsSelected === true)?.map(y=>y?.ID),
  //       PaymentIDs: stateInfo?.FolioDetail?.paymentArr?.filter(x=>x?.IsSelected === true)?.map(y=>y?.ID),
  //       AdvancePaymentIDs: stateInfo?.FolioDetail?.advPaymentArr?.filter(x=>x?.IsSelected === true)?.map(y=>y?.ID),
  //     },
  //     },
  //     fetchPolicy: 'no-cache',
  //   })
  useEffect(() => {
    var Email = ''
    Email =
      FolioDetails?.Registration !== null
        ? FolioDetails?.Registration?.Guest?.Contact.Email
        : FolioDetails?.Booking?.Registration?.filter(
            x => x?.IsPrincipal === true
          )[0]?.Guest?.Contact?.Email

    if (Email !== '') {
      setEmailShare(true)
      setEmailAddress(Email)
    }
  }, [FolioDetails])

  useEffect(() => {
    sendEmail &&
      EmailSend({
        variables: {
          Body: {
            Header: `${ShareButton.title}`,
            Body: FormContent,
          },
          HotelID: JSON.parse(localStorage.getItem('Hotel')).ID,
          Email: EmailAddress,
        },
      }).then(w => {
        if (w.data.EmailSend === true) {
          snackFunc('Sent Email Successfully!', false)
          setSimple(false)
        } else {
          snackFunc('Sent Email Fail!', false)
        }
      })
  }, [sendEmail])
  useEffect(() => {
    Template()
  }, [HotelXFolio])

  useEffect(() => {
    if (stateInfo?.FolioID || stateInfo?.Folio?.ID) {
      Template()
      Details()
    } else {
      Details2()
      Template()
    }
  }, [stateInfo?.FolioID || stateInfo?.Folio?.ID])

  // const { data: { FolioDetails } = { FolioDetails: null } } =
  //   useFolioDetailsQuery({
  //     variables: {
  //       ID: stateInfo?.FolioID,
  //       HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
  //     },
  //     fetchPolicy: 'no-cache',
  //   })
  const [EmailSend, { loading: loadingInsert }] = useEmailSendMutation()
  const ShareButton = {
    title: `${
      JSON.parse(localStorage.getItem('Hotel'))?.HotelName
    } has shared you a folio-${
      FolioDetails?.FolioNo || FolioDetailsWithIDs?.Booking?.BookingNo
    } `,
    url: imgUrl,
    img: `Click Here`,
  }

  var tourismTaxAmount = !FolioDetails?.TaxLedger
    ? !FolioDetailsWithIDs?.TaxLedger
      ? 0
      : FolioDetailsWithIDs?.TaxLedger?.map(x => {
          if (x.TaxType === TaxType.TourismTax) {
            return x.TaxAmount
          } else {
            return 0
          }
        }).reduce((a, b) => a + b, 0)
    : FolioDetails?.TaxLedger?.map(x => {
        if (x.TaxType === TaxType.TourismTax) {
          return x.TaxAmount
        } else {
          return 0
        }
      }).reduce((a, b) => a + b, 0)

      console.log(type, 'type')

  var Heritage = !FolioDetails?.TaxLedger
  ? !FolioDetailsWithIDs?.RoomCharges
    ? 0
    : FolioDetailsWithIDs?.RoomCharges.map(x => {
          if (x.Description === 'Heritage Tax') {
            return x.BaseAmount
          } else {
            return 0
          }
        })
        ?.reduce((a, b) => a + b, 0)
  : FolioDetails?.TaxLedger.map(x => {
      if (x.TaxType === TaxType.HeritageTax) {
        return x.TaxAmount
      } else {
        return 0
      }
    })?.reduce((a, b) => a + b, 0)

  var Levy = !FolioDetails?.TaxLedger
  ? !FolioDetailsWithIDs?.RoomCharges
    ? 0
    : FolioDetailsWithIDs?.RoomCharges.map(x => {
          if (x.Description === 'Levy') {
            return x.BaseAmount
          } else {
            return 0
          }
        })
        ?.reduce((a, b) => a + b, 0)
  : FolioDetails?.TaxLedger.map(x => {
      if (x.TaxType === TaxType.Levy) {
        return x.TaxAmount
      } else {
        return 0
      }
    })?.reduce((a, b) => a + b, 0)


  var StateFund = !FolioDetails?.TaxLedger
  ? !FolioDetailsWithIDs?.RoomCharges
    ? 0
    : FolioDetailsWithIDs?.RoomCharges.map(x => {
          if (x.Description === 'State Sustainability Fund') {
            return x.BaseAmount
          } else {
            return 0
          }
        })
        ?.reduce((a, b) => a + b, 0)
  : FolioDetails?.TaxLedger.map(x => {
      if (x.TaxType === TaxType.StateFund) {
        return x.TaxAmount
      } else {
        return 0
      }
    })?.reduce((a, b) => a + b, 0)
    
  var TourismTax = !FolioDetails?.TaxLedger
    ? !FolioDetailsWithIDs?.RoomCharges
      ? 0
      : FolioDetailsWithIDs?.RoomCharges.map(x => {
            if (x.Description === 'Tourism Tax') {
              return x.BaseAmount
            } else {
              return 0
            }
          })
          ?.reduce((a, b) => a + b, 0)
    : FolioDetails?.TaxLedger.map(x => {
        if (x.TaxType === TaxType.TourismTax) {
          return x.TaxAmount
        } else {
          return 0
        }
      })?.reduce((a, b) => a + b, 0)




  let PackagesPrice = 0
  var NumberCount = 1
  // const SelectedItem =[FolioDetails?.BillLedger, FolioDetails?.RoomCharges, FolioDetails?.Payment?.filter(x=>x?.PaymentClass === 'Advance')].filter(Boolean).flat(10) as any;

  const filteredBillLedger2 = FolioDetails?.BillLedger?.map( x => {
    const findVoidTrxWithSameDay = FolioDetails?.BillLedger?.filter(
      y => (
        y.ReversedBillLedgerID !== null &&
        y.ReversedBillLedgerID === x.ReversedBillLedgerID &&
        dateFormat(y?.TrxDate, "yyyy-mm-dd") === dateFormat(x?.TrxDate, "yyyy-mm-dd")
      )
    )

    if (findVoidTrxWithSameDay?.length < 2) { return x }
    else { return null }
  })?.filter(z => z !== null)

  const RoomChargesGrouping = (RoomChargesListing) => {

    const resArr = RoomChargesListing?.reduce((acc, curr) => {

      const existing = acc.find((x) => 
        x.Description === curr.Description && 
        x.TrxDate === curr.TrxDate && 
        x.RegistrationID === curr.RegistrationID &&
        x.SplitGroup === curr.SplitGroup &&
        x.FromRegistration === curr.FromRegistration
      )
  
      if (existing) {
        existing.BaseAmount += Number(curr.BaseAmount)
        existing.ServiceCharge += Number(curr.ServiceCharge)
        existing.TaxAmount += Number(curr.TaxAmount)
        existing.TrxAmount += Number(curr.TrxAmount)
      } else {
        acc.push({...curr, TaxRoomLegder: []})
      }
      
      return acc
    }, [])

    return resArr
  }


  const SelectedItem = [
    iseInvoice() === true
      ? filteredBillLedger2?.filter(
          x => x.IncidentalCharge?.IsOutlet !== true && x.IncidentalCharge?.IsEventInterface !== true && x.IncidentalCharge?.isEInvIntegrated === true
        )
      : filteredBillLedger2,
    stateInfo?.FolioType === 'NonGuest'? RoomChargesGrouping(FolioDetails?.RoomChargesSuspend ): RoomChargesGrouping(FolioDetails?.RoomCharges),
    FolioDetails?.TaxLedger?.filter(x => x?.TaxType === TaxType.StateFund),
    isNonGuestFolio
      ? []
      : FolioDetails?.Payment?.filter(x => x.PaymentClass === 'Refund'),
  ]
    ?.filter(Boolean)
    ?.flat(10) as any
  const SelectedItemWithIDs = [FolioDetailsWithIDs?.RoomCharges]
    ?.filter(Boolean)
    ?.flat(10) as any
 
  const iscityledgerdocs = () => {
  if(FolioDetails?.FolioNo !== null){
    if (FolioDetails?.DocumentType !== null){
      if((FolioDetails?.DocumentType?.startsWith('CN') ||  FolioDetails?.DocumentType?.startsWith('DN') 
          || FolioDetails?.DocumentType?.startsWith('OR') ||  
          FolioDetails?.DocumentType?.startsWith('RF') ) && OutGoingDocTemplate?.Folio !== null
        ){
          return true;
        } 
        else {
          return false
        }
    } else {
      if((FolioDetails?.FolioNo?.startsWith('CN') ||  FolioDetails?.FolioNo?.startsWith('DN') 
        || FolioDetails?.FolioNo?.startsWith('OR') ||  
        FolioDetails?.FolioNo?.startsWith('RF') ) && OutGoingDocTemplate?.Folio !== null )
        {
          return true;
        } 
        else {
          return false
        }
    }
  }
  else{
    return false
  }
  }

  const isnegativeamount  = (Amount_:number,payment) =>{
    
    const converterbar = Number(Amount_) < 0 
         ?
       
          `(${amtStr((Amount_)*-1)})`
          :  payment === 'payment' ? `(${amtStr((Amount_))})` : amtStr((Amount_))
   
          const converterbar2 = Number(Amount_) < 0 

          return payment?.hasOwnProperty('PaymentType') && 
            ((payment?.DocumentType === null && !payment?.ReceiptNo?.startsWith('HTRF')) ||
            (payment?.DocumentType !== null && !payment?.DocumentType?.startsWith('HTRF'))) && 
            !converterbar2 
              ? `(${ converterbar})` 
              : converterbar
     }

     const isRoundingnegativeamount  = (Amount_:number,payment, mode?) =>{
      let converterbar2 
      let converterbar 
  
      if(mode === 'CN') {
        converterbar2 = Number(Amount_) < 0 ? `(${amtStr((Amount_ * -1))})` : amtStr((Amount_ ))
        // converterbar2 = amtStr((Amount_ ))
      } else {
        converterbar =  Number(Amount_) < 0 ? amtStr((Amount_ * -1)) : `(${amtStr((Amount_))})`
      }
     
            
  
            return mode === 'CN' ? converterbar2 : converterbar
       }
  
  const selectedItemDesc = SelectedItem?.length === 0
    ? SelectedItemWithIDs?.filter(z => z?.Description !== 'Tourism Tax')
    : // : SelectedItem?.filter(z => z?.PaymentClass !== 'Refund')
    //: SelectedItem
    stateInfo?.Page === 'PaymentFolio' &&
      SelectedItem?.filter(y => y?.PaymentClass === 'Refund')?.length > 0
    ? SelectedItem?.filter(y => y?.PaymentClass === 'Refund')
    : SelectedItem?.filter(z => z?.PaymentClass !== 'Refund')
    
    console.log(selectedItemDesc, 'selectedItemDesc')
  var desc = selectedItemDesc
    ?.sort((x, y) => (x.TrxDate > y.TrxDate ? 1 : -1))
    ?.map((x, i) => {
      NumberCount += 1
      //  console.log(NumberCount)
      return `
      
      <tr  
      class="room-detail" 
      style="
      font-family: 'Poppins';
      -webkit-print-color-adjust: exact;
      -webkit-text-size-adjust:none;
      border: 2px solid #fff;
      background-color: #fff;
      color:'#212121';
        font-size: 10px;
        line-height: 15px;
        text-align: center;">
    <td style={"text-align: center; font-family: 'Poppins';
    -webkit-print-color-adjust: exact;
    -webkit-text-size-adjust:none;
    border: 2px solid #fff;"}>${dateFormat(
      x.TrxDate || x.PaymentDate,
      'dd/mm/yyyy'
    )}</td>
    <td style="text-align: left; line-height: 1.2;   font-family: 'Poppins';">${
      // console.log("x: ", x),
      !x.PaymentClass
        ? !x.IncidentalCharge
          ? !x.DepositClass
            ? x?.TrxDescription
            ? x.TrxDescription?.includes('Splitted') ? `* Room Charge : ${FolioDetails?.Registration?.Booking?.BookedRegistration?.find(
              y => y?.ID === x?.RegistrationID
            )?.Room?.RoomNo || x?.Registration?.Room?.RoomNo} : ${FolioDetails?.Registration?.Booking?.BookedRegistration?.find(
              y => y?.ID === x?.RegistrationID
            )?.Guest?.Contact?.FullName || x?.Registration?.Guest?.Contact?.FullName}` : x?.TrxDescription
              : x?.Description
              ? `${x?.Description} : ${
                  FolioDetails
                    ? FolioDetails?.Registration
                      ? FolioDetails?.Registration?.Booking?.BookedRegistration?.find(
                        y => y?.ID === x?.RegistrationID
                      )?.Room?.RoomNo
                      : FolioDetails?.Booking?.Registration?.find(
                          y => y?.ID === x?.RegistrationID
                        )?.Room?.RoomNo || x?.Registration?.Room?.RoomNo || ''
                    : FolioDetailsWithIDs?.Booking?.Registration?.find(
                        y => y?.ID === x?.RegistrationID
                      )?.Room?.RoomNo || FolioDetailsWithIDs?.Room?.RoomNo
                } : 
            ${
                  FolioDetails
                    ? FolioDetails?.Registration
                      ? FolioDetails?.Registration?.Booking?.BookedRegistration?.find(
                        y => y?.ID === x?.RegistrationID
                      )?.Guest?.Contact?.FullName
                      : FolioDetails?.Booking?.Registration?.find(
                          y => y?.ID === x?.RegistrationID
                        )?.Guest?.Contact?.FullName || ''
                    : FolioDetailsWithIDs?.Booking?.Registration?.find(
                        y => y?.ID === x?.RegistrationID
                      )?.Guest?.Contact?.FullName || FolioDetailsWithIDs?.Guest?.Contact?.FullName
                }`
              : x.TaxType
              ? (x?.TaxDescription || x.TaxType)
              : x?.TransactionType
            : x.DepositClass
          : x.Description
          ? x.Description
          : `${x.IncidentalCharge.Description}` + ((x.IncidentalCharge.Description)?.toLowerCase()?.includes('room charge') && x?.Registration !== null ? ` : ${x?.Registration?.Room?.RoomNo} : ${x?.Registration?.Guest?.Contact?.FullName}` : '')  
        : x.PaymentClass === 'Refund'
        ? `Refund by ${x.PaymentType  === "BankTT" ? "Bank TT" : x?.PaymentType}`
        : `${x.PaymentType  === "BankTT" ? "Bank TT" : x?.PaymentType} Payment`
    } </td>
    ${
      OutGoingDocTemplate !== null
        ? `<td style="text-align: center;   font-family: 'Poppins';">${
            x.Qty !== undefined ? x.Qty : '1'
          }</td>`
        : `<td style="text-align: center;   font-family: 'Poppins';">${
         ''
        }</td>`
    }
    <td style={"font-family: 'Poppins';
    -webkit-print-color-adjust: exact;
    -webkit-text-size-adjust:none;
    border: 2px solid #fff;"}>${
      !x.ReferenceNo ? (!x?.CardNo ? '' : x?.CardNo) : x.ReferenceNo
    }</td>
    <td style="text-align: right; font-family: 'Poppins';
    -webkit-print-color-adjust: exact;
    -webkit-text-size-adjust:none; padding-right: 2%;">${

      x.DepositAmount
        ? isnegativeamount(x.DepositAmount,x?.Description  === 'Advance - Payment' ? 'payment' : '')
        : PackagesPrice
        ? isnegativeamount(x.BaseAmount + PackagesPrice,x?.Description  === 'Advance - Payment' ? 'payment' : '')
        : x.BaseAmount
        ? isnegativeamount(x.BaseAmount,x?.Description  === 'Advance - Payment' ? 'payment' : '')
        : x.TaxAmount
        ? isnegativeamount(x.TaxAmount,x?.Description  === 'Advance - Payment' ? 'payment' : '')
        : isnegativeamount(x.Amount,x?.Description  === 'Advance - Payment' ? 'payment' : '')
      // !x.Amount
      //   ? !x.BaseAmount
      //     ? x.TaxAmount
      //     : x.TaxAmount
      //     ? x.DepositAmount
      //     : PackagesPrice
      //     ? x.BaseAmount + PackagesPrice
      //     : x.BaseAmount
      //   : x.Amount
    }</td>
    </tr>
    `
    })
  //Arr to Object
  var item = Object.assign({}, desc)
  //Object to string
  var EachFolioItem = ''
  for (const [key, value] of Object.entries(item)) {
    EachFolioItem = EachFolioItem + value
  }
  console.log(stateInfo, mode, 'stateInfo')


  var desc2 = FolioDetails?.DepositLedger
  ? FolioDetails?.DepositLedger?.map((x, i) => {
      NumberCount += 1
      return `

<tr  
class="room-detail" 
style="
font-family: 'Poppins';
-webkit-print-color-adjust: exact;
-webkit-text-size-adjust:none;
border: 2px solid #fff;
color:'#212121';
font-size: 10px;
line-height: 15px;
text-align: center;">
<td style={"text-align: center; font-family: 'Poppins';
-webkit-print-color-adjust: exact;
-webkit-text-size-adjust:none;
border: 2px solid #fff;"}>${dateFormat(x.TrxDate, 'dd/mm/yyyy')}</td>
<td style="text-align: left; line-height: 1.2;">
${`${x?.DepositClass}`} </td>
<td style={"font-family: 'Poppins';
-webkit-print-color-adjust: exact;
-webkit-text-size-adjust:none;
border: 2px solid #fff;"}>${''}</td>
<td style={"font-family: 'Poppins';
-webkit-print-color-adjust: exact;
-webkit-text-size-adjust:none;
border: 2px solid #fff;"}>${x?.ReferenceNo ? x?.ReferenceNo : ''}</td>
<td style="text-align: right;font-family: 'Poppins';
-webkit-print-color-adjust: exact;
-webkit-text-size-adjust:none;
border: 2px solid #fff;  padding-right:2%;">${amtStr(x?.DepositAmount)}</td>
</tr>
`
    })
  : FolioDetails?.TaxLedger
  ? FolioDetails?.TaxLedger?.sort((x, y) => (x.TrxDate > y.TrxDate ? 1 : -1))
  ?.map((x, i) => {
      NumberCount += 1
      //  console.log(NumberCount)
      return `

<tr  
class="room-detail" 
style="
font-family: 'Poppins';
-webkit-print-color-adjust: exact;
-webkit-text-size-adjust:none;
border: 2px solid #fff;
background-color:'#212121';
color:'#212121';
font-size: 10px;
line-height: 15px;
text-align: center;">
<td style={"text-align: center; font-family: 'Poppins';
-webkit-print-color-adjust: exact;
-webkit-text-size-adjust:none;
border: 2px solid #fff;"}>${dateFormat(x.TrxDate, 'dd/mm/yyyy')}</td>
<td style="text-align: left; line-height: 1.2;">
${`${x?.TaxDescription || x?.TaxType}`} </td>
<td style={"font-family: 'Poppins';
-webkit-print-color-adjust: exact;
-webkit-text-size-adjust:none;
border: 2px solid #fff;"}>${''}</td>
<td style={"font-family: 'Poppins';
-webkit-print-color-adjust: exact;
-webkit-text-size-adjust:none;
border: 2px solid #fff;"}>${''}</td>
<td style="text-align: right;font-family: 'Poppins';
-webkit-print-color-adjust: exact;
-webkit-text-size-adjust:none;
border: 2px solid #fff; padding-right:2%;">${amtStr(x?.TaxAmount)}</td>
</tr>
`
    })
  : FolioDetails?.Payment?.map((x, i) => {
      NumberCount += 1
      //  console.log(NumberCount)
      return `

<tr  
class="room-detail" 
style="
font-family: 'Poppins';
-webkit-print-color-adjust: exact;
-webkit-text-size-adjust:none;
border: 2px solid #fff;
background-color:'#212121';
color:'#212121';
font-size: 10px;
line-height: 15px;
text-align: center;">
<td style={"text-align: center; font-family: 'Poppins';
-webkit-print-color-adjust: exact;
-webkit-text-size-adjust:none;
border: 2px solid #fff;"}>${dateFormat(x.PaymentDate, 'dd/mm/yyyy')}</td>
<td style="text-align: left; line-height: 1.2;">
${`${
x.PaymentClass === 'Refund'
? `Refund by ${x.PaymentType  === "BankTT" ? "Bank TT" : x?.PaymentType}`
: `${x.PaymentType  === "BankTT" ? "Bank TT" : x?.PaymentType} Payment`
}`} </td>
<td style={"font-family: 'Poppins';
-webkit-print-color-adjust: exact;
-webkit-text-size-adjust:none;
border: 2px solid #fff;"}>${''}</td>
<td style={"font-family: 'Poppins';
-webkit-print-color-adjust: exact;
-webkit-text-size-adjust:none;
border: 2px solid #fff;"}>${x?.ReferenceNo}</td>
<td style="text-align: right;font-family: 'Poppins';
-webkit-print-color-adjust: exact;
-webkit-text-size-adjust:none;
border: 2px solid #fff;  padding-right:2%;">${amtStr(x?.Amount)}</td>
</tr>
`
    })

              //Arr to Object
  var item2 = Object.assign({}, desc2)

  //Object to string
  var EachFolioItem2 = ''
  for (const [key, value] of Object.entries(item2)) {
    EachFolioItem2 = EachFolioItem2 + value
  }

  function getSum(total, num) {
    return total + num
  }
  const isSelectedItemGovTax = SelectedItem?.some(
    x => x?.TaxType === TaxType.GovTax
  )
  console.log(isSelectedItemGovTax, '????/')

  const documentType = (Data) => {
    if (Data?.Folio?.DocumentType !== null) {
      return Data?.Folio?.DocumentType || Data?.DocumentType
    } else {
      return Data?.Folio?.FolioNo || Data?.FolioNo
    }
  }

  const ShareReceiptButton = {
    title: `${
      JSON.parse(localStorage.getItem('Hotel'))?.HotelName
    } has shared you a Receipt-${
      PaymentDetails
        ? documentType(PaymentDetails)?.slice(0, 2) === 'RF' ||
          documentType(PaymentDetails)?.slice(0, 1) === 'D'
          ? PaymentDetails?.Folio?.FolioNo
          : PaymentDetails?.ReceiptNo
        : documentType(AdvancePaymentDetails)?.slice(0, 2) === 'RF' ||
          documentType(AdvancePaymentDetails)?.slice(0, 1) === 'D'
        ? AdvancePaymentDetails?.Folio?.FolioNo
        : AdvancePaymentDetails?.ReceiptNo
    } please click`,
    url: imgUrl,
    img: `Click Here`,
  }

  const onSubmit = blob => {
    BISharedGAlleryInsert({
      variables: {
        HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
        AttachmentInput: blob,
      },
    }).then(i => {
      i.data.BISharedGAlleryInsert
        ? setimgUrl(i.data.BISharedGAlleryInsert.Gallery.ImageURL)
        : console.log('false')
    })
  }
  const isnonguestandsuspend = () => {

    return OutGoingDocTemplate !== null && isNonGuestFolio === true 
      ? true
      : false
  }


  console.log(stateInfo, 'stateInfo')

  const subAmount = (SelectedItem?.reduce((acc, curr) => {
    return acc + (curr.BaseAmount || curr.TaxAmount || 0)
  }, 0) || 0) || ((FolioDetailsWithIDs?.RoomCharges?.filter(x => x.Description !== 'Advance - Payment')?.reduce((acc, curr) => {
    return acc + curr.BaseAmount
  }, 0) || 0)- FolioDetailsWithIDs?.RoomCharges?.filter(x => x.Description === 'Advance - Payment')?.reduce((acc, curr) => {
    return acc + curr.BaseAmount
  }, 0) || 0)

  //  +(stateInfo?.Folio?.reduce((acc, curr) => {
  //   return acc + curr.TrxAmount
  // }, 0) || 0) 

  
  
  const conditionSub =  subAmount

  let totalpaymentcityeldger = (FolioDetails?.Payment?.filter(t => t.PaymentType === "CityLedger").reduce((acc, curr) => {
    return acc + (curr?.PaymentClass === 'Refund' ? -curr.Amount : curr.Amount)
  }, 0) || 0) 

  let totalpayment = (FolioDetails?.Payment?.filter(t => t.Amount > 0 && t.PaymentType !== "CityLedger")?.reduce((acc, curr) => {
    return acc + curr.Amount
  }, 0) || 0)*-1 + (FolioDetails?.Payment?.filter(t => t.Amount > 0 && 
      (t?.DocumentType === null && t?.ReceiptNo?.startsWith('HTRF')) || (t?.DocumentType !== null && t?.DocumentType?.startsWith('HTRF'))
    )?.reduce((acc, curr) => {
      return acc + curr.Amount
  }, 0) || 0)
  let newitem2: string = ``

  if (FolioDetails?.Payment !== null) {
    FolioDetails?.Payment?.sort((a, b) =>
      a.ReceiptNo?.localeCompare(b.ReceiptNo)
    ).filter(x => {if(OutGoingDocTemplate !== null) {return x.PaymentType !== "CityLedger"} else {return true}} 
      )?.filter(z => z?.PaymentClass !== 'Rounding').map((x, y) => {
      let item = `
        <tr  
        class="room-detail" 
        style="
          font-family: 'Poppins';
          -webkit-print-color-adjust: exact;
          -webkit-text-size-adjust:none;
          border: 0px solid #fff;
          background-color: #fff;
          color: #2c2c2c;
          font-size: 10px;
          line-height: 15px;
          text-align: center;">
          <td style="text-align: center;   font-family: 'Poppins';">${
            x
              ? dateFormat(x?.PaymentDate, 'dd/mm/yyyy')
              : dateFormat(AdvancePaymentDetails?.PaymentDate, 'dd/mm/yyyy')
          }</td>
          <td style="text-align: left;   font-family: 'Poppins';">${
            isNonGuestFolio
              ? x?.PaymentClass === 'Refund'
              ? `Refund by ${x?.PaymentType === "BankTT" ? "Bank TT" : x?.PaymentType}`
              : `${x?.PaymentType  === "BankTT" ? "Bank TT" : x?.PaymentType} Payment ${x?.Amount < 0 && x?.ReversedPaymentID !== x?.ID ? 'Void' : ''}`
              : x
              ? documentType(FolioDetails)?.slice(0, 2) === 'RF'
                ? `${x?.PaymentClass} - ${
                    DepositClass?.DepositClass === 'SecurityDeposit'
                      ? 'Security Deposit'
                      : 'Key Card Deposit'
                  }`
                : documentType(FolioDetails)?.slice(0, 1) === 'D' &&
                  documentType(FolioDetails)?.slice(0, 2) !== 'DN'
                ? FolioDetails?.DepositLedger &&
                  FolioDetails?.DepositLedger[0]?.DepositClass ===
                    'SecurityDeposit'
                  ? 'Security Deposit'
                  : 'Key Card Deposit'
                : x?.PaymentClass === 'Refund'
                ? `${(x?.Amount < 0 && x?.ReversedPaymentID !==null && x?.ReversedPaymentID !== x?.ID) ? 'Voided' : ''} Refund by ${x?.PaymentType  === "BankTT" ? "Bank TT" : x?.PaymentType}`
                : x?.PaymentType === 'CityLedger'
                ? // ? `Charge to City Ledger12 - ${PaymentDetails?.Registration?.Booking?.DebtorAccount}`
                  `Charge to City Ledger - ${x?.DebtorAccount}`
                : `Payment -${x?.PaymentType  === "BankTT" ? "Bank TT" : x?.PaymentType} ${x?.Amount < 0 && x?.ReversedPaymentID !== x?.ID ? 'Void' : ''}`
              : AdvancePaymentDetails?.Booking?.DebtorAccount === null
              ? `${AdvancePaymentDetails?.PaymentType  === "BankTT" ? "Bank TT" : x?.PaymentType} Payment  ${x?.Amount < 0 && x?.ReversedPaymentID !== x?.ID ? 'Void' : ''}`
              : `Charge to City Ledger - ${AdvancePaymentDetails?.Booking?.DebtorAccount}`
          }</td>
          <td style="text-align: center;   font-family: 'Poppins';">
          </td>
   
          <td style="text-align: left;  font-family: 'Poppins'; padding-left:0px;">${x ? x?.ReceiptNo : ''}</td>
   
          <td style="text-align: right; padding-right: 2%;   font-family: 'Poppins';">
          ${
          x ? x?.PaymentClass !== 'Refund' && x?.Amount < 0 && x?.ReversedPaymentID !== x?.ID ? amtStr(-x?.Amount) : isnegativeamount(Number(x?.Amount),x)
          :isnegativeamount(AdvancePaymentDetails?.Amount,x)
          }</td>
    </tr>`

      newitem2 = `` + newitem2.concat(item)
    })
  }



  let TotalAmount = isNonGuestFolio
    ? NonGuestFolioTotal
  : FolioDetails === null
  ? stateInfo?.Total
  : stateInfo?.Page === 'PaymentFolio' &&
    SelectedItem?.filter(y => y?.PaymentClass === 'Refund')?.length > 0
  ? 
      SelectedItem?.filter(y => y?.PaymentClass === 'Refund')
        ?.map(x => x?.Amount)
        ?.reduce((a, b) => a + b, 0)
    
  : FolioDetails?.FolioAmount

  /// Disbursement & Reimbursement Item
  //* start */
  const SelectedItemEO = filteredBillLedger2?.filter(
    x => x.IncidentalCharge?.IsOutlet === true || 
      x.IncidentalCharge?.IsEventInterface === true || 
      x.IncidentalCharge?.isEInvIntegrated !== true
  )

  const subTotalEO = SelectedItemEO?.reduce((acc, curr) => {
    return acc + curr?.BaseAmount
  }, 0)

  const roundingAmount = FolioDetails?.Payment?.filter(z => z?.PaymentClass === 'Rounding')
    ?.map(x => x?.Amount || null)
    .reduce((a, b) => (a + b), 0)

  const TotalEO = SelectedItemEO?.reduce((acc, curr) => {
    return acc + curr?.TrxAmount
  }, 0) - Number(SelectedItemEO?.length > 0 ? roundingAmount : 0)

  const GrandTotal = TotalAmount

  var descEO = SelectedItemEO?.sort((x, y) => (x.TrxDate > y.TrxDate ? 1 : -1))?.map(x => {
    return `
      <tr class="room-detail" 
        style="font-family: 'Poppins'; -webkit-print-color-adjust: exact; 
        -webkit-text-size-adjust:none; border: 2px solid #fff; background-color: #fff; 
        color: #212121; font-size: 10px; text-align: center;"
      >
        <td style="text-align: center; font-family: 'Poppins';">
          ${dateFormat(x.TrxDate, 'dd/mm/yyyy')}
        </td>
        <td style="text-align: left; line-height: 1.2; font-family: 'Poppins';">
          ${!x.IncidentalCharge ? x.Description : x.IncidentalCharge.Description} 
        </td>
        ${OutGoingDocTemplate !== null
          ? `<td style="text-align: center; font-family:'Poppins';">
              ${x.Qty !== undefined ? x.Qty : '1'}
            </td>`
          : `<td style="text-align: center; font-family:'Poppins';">
              ${''}
            </td>`
        }
        <td style="text-align: left; line-height: 1.2; font-family: 'Poppins'; border: 2px solid #fff;">
          ${!x.ReferenceNo ? '' : x.ReferenceNo}
        </td>
        <td style="text-align: right; line-height: 1.2; padding-right: 2%; font-family: 'Poppins';">
          ${isnegativeamount(Number(x.BaseAmount), x)}
        </td>
      </tr>
    `
  })

  var itemEO = Object.assign({}, descEO)
  var EachFolioItemEO = ''
  for (const [key, value] of Object.entries(itemEO)) {
    EachFolioItemEO = EachFolioItemEO + value
  }

  const taxListingEO = () => {
    let taxList: any = []
    let billTaxGrouping: any = []
    var EachTaxItemEO = ''

    if (FolioDetails?.RoomCharges !== undefined && FolioDetails?.BillLedger !== undefined) {
      if (FolioDetails?.BillLedger !== null) {
        billTaxGrouping = [].concat(
          ...FolioDetails?.BillLedger?.filter(
            f => f.IncidentalCharge?.isEInvIntegrated !== true ||
            f.IncidentalCharge?.IsOutlet === true || 
            f.IncidentalCharge?.IsEventInterface === true
          )?.map(x => {
            if (x?.TaxBillLedger) {
              return x?.TaxBillLedger
            } else {
              return
            }
          })?.filter(y => y !== undefined)
        )
      }

      const Listing = billTaxGrouping

      taxList = Listing?.reduce((acc, curr) => {
        const { TaxID, TaxInfo, TaxAmount, TaxRate } = curr

        const existing = acc.find((x) => x.TaxID === TaxID)

        if (existing) {
          existing.TaxAmount += TaxAmount
        } else {
          acc.push({TaxID, TaxRate, TaxInfo, TaxAmount})
        }

        return acc;
      }, [])
    }

    if (taxList?.length > 0) {
      const taxListArr = taxList?.map((x) => {
        return `
          <tr
            class="" 
            style="font-family: 'Poppins';
            -webkit-print-color-adjust: exact;
            -webkit-text-size-adjust:none;
            background-color: #fff;
            color: #2c2c2c;
            font-size: 10px;
            line-height: 15px;"
          >
            <td
              style="font-weight: 300; padding-left: 6px;"
            >
              ${x?.TaxInfo?.code} (${x?.TaxRate}%)
            </td>
            <td
              style="font-weight: 300; item-align:right; text-align: right;"
            >
              ${isnegativeamount(Number(x?.TaxAmount || 0.00), '')} 
            </td>
          </tr>
        `
      });

      var docItem = Object.assign({}, taxListArr)
      //Object to string
      for (const [key, value] of Object.entries(docItem)) {
        EachTaxItemEO = EachTaxItemEO + value
      }
    }

    return taxList?.length > 0 ? EachTaxItemEO : ''
  }

  const externalOutlet = SelectedItemEO?.length > 0 
    ? `<table width="100%" border="0" cellspacing="0" cellpadding="0" style="padding-bottom: 10px">
        <tr style="font-family: 'Poppins'; -webkit-print-color-adjust: exact; -webkit-text-size-adjust:none; 
          background-color: #FFFFFF; color: #FFFFFF; font-size: 10px; line-height: 28px; text-align: center;"
        >
          <th style="background-color: #FFFFFF; color: #FFFFFF; width:160px;"></th>
          <th style="background-color: #FFFFFF; color: #FFFFFF; width:315px;"></th>
          <th style="background-color: #FFFFFF; color: #FFFFFF; width:30px;"></th>
          <th style="background-color: #FFFFFF; color: #FFFFFF; width:160px;"></th>
          <th style="background-color: #FFFFFF; color: #FFFFFF; width:120px;"></th>
        </tr>
        <tr style="background-color: #fff; color: #2c2c2c; font-size: 10px; line-height: 15px;">
          <td colspan="4" style="font-weight: 800; text-decoration: underline; padding-bottom: 2px; padding-left: 6%">
            Disbursement/Reimbursement
          </td>
        </tr>
        ${EachFolioItemEO}
      </table>
      <div style="width: 100%; display: flex; margin-top: 5px;">           
        <div style="width: 50%;  margin: 4px; padding: 6px; "></div>
        <div style="width: 50%; display: block; padding-left: 25%; padding-right: 3px;">    
          <table 
            width="100%" border="0" cellspacing="0" cellpadding="0"  
            style="margin-bottom: 12px; font-family: 'Poppins';
            -webkit-print-color-adjust: exact; -webkit-text-size-adjust:none;" 
          >
            <tr 
              class="" 
              style="background-color: #fff;
              color: #2c2c2c;
              font-size: 10px;
              line-height: 15px;"
            >
              <td style="font-weight: 300; padding-left: 6px;">
                Sub Total
              </td>
              <td style="font-weight: 300; item-align:right; text-align: right;">
                ${isnegativeamount(Number(subTotalEO.toFixed(2)), '')}
              </td>
              <td style="width:4%;"></td>
            </tr>
            ${taxListingEO()}
            <tr 
              class="" 
              style="background-color: #fff;
              color: #2c2c2c;
              font-size: 10px;
              line-height: 15px;"
            >
              <td style="font-weight: 300; padding-left: 6px;">
                Rounding
              </td>
              <td style="font-weight: 300;  item-align:right;text-align: right;">
                ${isRoundingnegativeamount(Number(amtStr(FolioDetails?.Payment?.filter(z => z?.PaymentClass === 'Rounding')?.map(x => x?.Amount || null)
                .reduce((a, b) => (a + b), 0))), '', documentType(FolioDetails)?.includes('CN') ? 'CN' : '')  || amtStr(0)}
              </td>
            </tr>
            <tr 
              class="" 
              style="background-color: #fff;
              color: #2c2c2c;
              font-size: 10px;
              line-height: 15px;"
            >
              <td style="font-weight: 800; padding-left: 6px;">
                TOTAL
              </td>
              <td style="font-weight: 800;  item-align:right; text-align: right; color:#2c2c2c">
                ${isnegativeamount(Number(TotalEO.toFixed(2)), '')}
              </td>
              <td style="width:4%;"></td>
            </tr>
          </table>
        </div>              
      </div>
      <div style="width: 100%; display: flex; padding-bottom: 10px;">
        <div style="width: 50%; margin: 4px; padding: 6px; "></div>
        <div style="width: 50%; display: block; padding-left: 25%; padding-right: 3px; ">
          <table width="100%" border="0" cellspacing="0" cellpadding="0" 
            style="margin-bottom: 12px; font-family: 'Poppins'; -webkit-print-color-adjust: exact; -webkit-text-size-adjust:none;" 
          >
            <tr class=" " style="background-color: #fff; color: #2c2c2c; font-size: 10px; line-height: 1px;">
              <td style="font-weight: 800; padding-left: 6px;">
                GRAND TOTAL
              </td>
              <td style="font-weight: 800; item-align:right; text-align: right; color:#2c2c2c">
                ${isnegativeamount(Number(GrandTotal.toFixed(2)), '')}
              </td>
              <td style="width:4%; "></td>
            </tr>
          </table>
        </div>
      </div>`
    : ``
  //* end */

  const outstandingbalancetemplate = `	  <div style="width: 100%; display: flex; margin-top: 10px;">
                        
<div style="width: 50%;  margin: 4px; padding: 6px; ">

</div>

<div style="width: 50%; display: block; padding-left: 15%;padding-right: 6px;">
       
       <table width="100%" border="0" cellspacing="0" cellpadding="0"  style="margin-bottom: 10px; font-family: 'Poppins';
   -webkit-print-color-adjust: exact;
   -webkit-text-size-adjust:none;" >

<tr class="" style="      background-color: #fff;
           color: #2c2c2c;
           font-size: 10px;
           line-height: 15px;
           ;
           ">
               <td style="
               font-weight: 800; padding-left: 0px; font-family:'Poppins';">OUTSTANDING BALANCE</td>
               <td style="
               font-weight: 800; font-family:'Poppins';  item-align:right; text-align: right; padding-left: 5px;
               color:#2c2c2c
               ">${(isnegativeamount(Number(totalpaymentcityeldger),''))}</td>
<td style="width:4%;
               "></td>
           </tr>
       </table>
</div>

</div>`

const outstandingbalanceFunc = () =>{
  let result = ''

  if(iscityledgerdocs () === true || isinvoice() === false )
  {
    result = ''
  }
else  if(FolioDetailsWithIDs !== null){
    result = ''
  }

  else{
result = outstandingbalancetemplate
  }
  return result
}

let address =   
FolioDetails?.BeneficiaryAddress ||
AddressConverter({
  address: FolioDetails?.Registration?.Guest?.Contact?.Address?.address,
  address2: FolioDetails?.Registration?.Guest?.Contact?.Address?.address2,
  city: FolioDetails?.Registration?.Guest?.Contact?.Address?.city,
  country: FolioDetails?.Registration?.Guest?.Contact?.Address?.country,
  postCode: FolioDetails?.Registration?.Guest?.Contact?.Address?.postCode,
  state: FolioDetails?.Registration?.Guest?.Contact?.Address?.state,
}) ||
AddressConverter({
  address:
    FolioDetails?.Booking?.Registration[0]?.Guest?.Contact?.Address
      ?.address,
  address2:
    FolioDetails?.Booking?.Registration[0]?.Guest?.Contact?.Address
      ?.address2,
  city: FolioDetails?.Booking?.Registration[0]?.Guest?.Contact?.Address
    ?.city,
  country:
    FolioDetails?.Booking?.Registration[0]?.Guest?.Contact?.Address
      ?.country,
  postCode:
    FolioDetails?.Booking?.Registration[0]?.Guest?.Contact?.Address
      ?.postCode,
  state:
    FolioDetails?.Booking?.Registration[0]?.Guest?.Contact?.Address
      ?.state,
}) ||
AddressConverter({
  address: FolioDetailsWithIDs?.Guest?.Contact?.Address?.address,
  address2: FolioDetailsWithIDs?.Guest?.Contact?.Address?.address2,
  city: FolioDetailsWithIDs?.Guest?.Contact?.Address?.city,
  country: FolioDetailsWithIDs?.Guest?.Contact?.Address?.country,
  postCode: FolioDetailsWithIDs?.Guest?.Contact?.Address?.postCode,
  state: FolioDetailsWithIDs?.Guest?.Contact?.Address?.state,
})
const isinvoice = () => {
  
  if((FolioDetails?.DocumentType === null && FolioDetails?.FolioNo?.startsWith('INV')) ||
    (FolioDetails?.DocumentType !== null && FolioDetails?.DocumentType?.startsWith('INV'))
  ) {
    return true;
  } else if (
    (FolioDetails?.DocumentType === null &&
      (FolioDetails?.FolioNo?.startsWith('CN') || FolioDetails?.FolioNo?.startsWith('DN') || 
      FolioDetails?.FolioNo?.startsWith('OR') || FolioDetails?.FolioNo?.startsWith('RF'))) ||
    (FolioDetails?.DocumentType !== null &&
      (FolioDetails?.DocumentType?.startsWith('CN') || FolioDetails?.DocumentType?.startsWith('DN') || 
      FolioDetails?.DocumentType?.startsWith('OR') || FolioDetails?.DocumentType?.startsWith('RF')))
  ){
    return true;
  } else {
    return false
  }
}
//maya only <3
const agentname = () =>{
  let result  = ''
  
  if(OutGoingDocTemplate !== null && DebtorFolioInfo !== null){
  
      result = `Guest Name`
 
  }
  else{
    result = (!FolioDetails?.Registration?.Booking?.Debtor?.DebtorAccount && !FolioDetails?.Booking?.Debtor?.DebtorAccount)
      ? ''
      : `Company/Travelling Agent Name:${FolioDetails?.Registration?.Booking?.Debtor?.Name || FolioDetails?.Booking?.Debtor?.Name}` 
  }
      
      return result;
        
    }




    const HotelMayaTax = (stateInfo?.Tax || taxListing())?.map(x => {
      return `
      <tr
        class="" 
        style="background-color: #fff;
        color: #2c2c2c;
        font-size: 10px;
        line-height: 15px;"
      >
        <td
          style="font-weight: 300; padding-left: 6px;"
        >
          ${x?.TaxInfo?.code || x?.taxCode || x?.TaxCode} (${x?.TaxRate || x?.taxRate || x?.TaxRate}%)
        </td>
        <td
          style="font-weight: 300; item-align:right; text-align: right;"
        >
          ${isnegativeamount(Number(x?.TaxAmount || x?.taxAmount || x?.Value || 0.00), '')}
        </td>
      </tr>
    `
    })

    var docItem = Object.assign({}, HotelMayaTax)
    //Object to string
    var HotelMayaTaxItem = ''
    for (const [key, value] of Object.entries(docItem)) {
      HotelMayaTaxItem = HotelMayaTaxItem + value
    }

  const isDocType = (type) => {
    const docType = (FolioDetails?.DocumentType === null && FolioDetails?.FolioNo?.startsWith(type)) ||
      (FolioDetails?.DocumentType !== null && FolioDetails?.DocumentType?.startsWith(type))
    
    return docType
  }

  const eInvoiceQr = () => {
    const condition = FolioDetails?.is_einvoice === true && 
      HotelDetails?.Company?.isEInvIntegrated === true &&
      dateFormat(FolioDetails?.FolioDate, 'yyyy-mm-dd') >= dateFormat(HotelDetails?.Company?.eInvStartDate, 'yyyy-mm-dd') &&
      dateFormat(FolioDetails?.FolioDate, 'yyyy-mm-dd') <= dateFormat(HotelDetails?.Company?.eInvEndDate, 'yyyy-mm-dd') &&
      FolioDetails?.QrCodeUrl !== null

    const result = condition === true 
      ? `<td class="qr-code" rowspan="5"; style="width:100%; text-align:right; justify-content:end; position: relative; vertical-align: center">
          <span style="display: flex; justify-content: center; text-align: center; padding-bottom: 4px">
            <img 
              src="${FolioDetails?.QrCodeUrl}"
              alt="QR Code" 
              style="width: 85px; display: inline-block;"
            >
          </span>
          <div style="width: 100%; right: 0; line-height: 1">
            <span style="font-size:9px; display: flex; justify-content: center; text-align: center;">
              Scan within 72 hours if
            </span>
            <span style="font-size:9px; display: flex; justify-content: center; text-align: center;">
              e-Invoice is required
            </span>
          </div>
        </td>`
      :``
    return result;
  }
  console.log(HotelDetails,stateInfo,state, "rawrr");

  const amountTotal = stateInfo?.Page === 'PaymentFolio' &&
    SelectedItem?.filter(y => y?.PaymentClass === 'Refund')?.length > 0
      ? SelectedItem?.filter(y => y?.PaymentClass === 'Refund')
        ?.map(x => x?.Amount)?.reduce((a, b) => a + b, 0)
      : stateInfo?.Page === 'ConfirmationFolio' 
        ? stateInfo?.Total
        : (SelectedItem?.reduce((acc, curr) => {
            return acc + (curr.TrxAmount || curr.TaxAmount || 0)
          }, 0) || 0) + TourismTax + Heritage + Levy - (iseInvoice() === true && SelectedItemEO?.length > 0 ? 0 : roundingAmount)

  const TourismTaxType = !FolioDetails?.TaxLedger
    ? !FolioDetailsWithIDs?.TaxLedger
      ? ''
      : TourismTax !== 0
        ? `
          <td style="cfont-weight: 300; padding-left: 6px;">
            Tourism Tax
          </td>
          <td style="font-weight: 300;  item-align:right;text-align: right;">
            ${amtStr(isnegativeamount(Number(TourismTax),''))}
          </td>`
        : `
          <td style="font-weight: 300; padding-left: 6px;">
            Tourism Tax
          </td>
          <td style="font-weight: 300;  item-align:right;text-align: right;">
            ${amtStr(isnegativeamount(Number(TourismTax),''))}
          </td>`
    : TourismTax !== 0
      ? `
        <td style="font-weight: 300; padding-left: 6px;">
          Tourism Tax
        </td>
        <td style="font-weight: 300;  item-align:right;text-align: right;">
          ${isnegativeamount(Number(TourismTax),'')}
        </td>`
      : ''
  
  const TotalRounding = (iseInvoice() === true && SelectedItemEO?.length > 0) 
    ? '' 
    :`
      <td style=" font-weight: 300; padding-left: 6px;">
        Rounding
      </td>
      <td style="font-weight: 300;  item-align:right;text-align: right;">
        ${
          isRoundingnegativeamount(Number(amtStr(FolioDetails?.Payment?.filter(z => z?.PaymentClass === 'Rounding')
            ?.map(x => x?.Amount || null)
            .reduce((a, b) => (a + b), 0))), '', documentType(FolioDetails)?.includes('CN') ? 'CN' : '')  || amtStr(0)
        }
      </td>`
  
  const TaxTypeInput = type === 'REFUND' || documentType(FolioDetails)?.slice(0, 1) === 'D'
    ? ''
    : SelectedItem?.length > 0
      ? stateInfo?.Page === 'PaymentFolio' && 
        SelectedItem?.filter(y => y?.PaymentClass === 'Refund')?.length > 0
        ? ''
        : !FolioDetails?.TaxLedger
          ? ''
          : Heritage === 0 && Levy === 0 && StateFund === 0
            ? ''
            : Heritage > 0
              ? `
                <td style="font-weight: 300; padding-left: 6px;">
                  Heritage Tax
                </td>
                <td style="font-weight: 300;  item-align:right;text-align: right;">
                  ${amtStr(Heritage)}
                </td`
              : Levy > 0
              ? `
                <td style="font-weight: 300; padding-left: 6px;">
                  Levy Tax
                </td>
                <td style="font-weight: 300; item-align:right; text-align: right;">
                  ${amtStr(Levy)}
                </td>`
              : ''
      : ''

  const subtotal = FolioDetails?.Payment?.filter(x => x.PaymentClass === 'Refund') .length > 0 && 
    stateInfo?.Page === 'PaymentFolio'
    ? ''
    : `<tr 
        class="" 
        style="background-color: #fff;
          color: #2c2c2c;
          font-size: 10px;
          line-height: 15px;"
      >
        <td style="font-weight: 300; padding-left: 6px;">
          Sub Total
        </td>
        <td style=" font-weight: 300; item-align:right; text-align: right;">
          ${isnegativeamount(conditionSub,'')}
        </td>
        <td style="width:4%;"></td>
      </tr>`

  const AmountItem = `
    <div style="width: 100%; display: flex; margin-top: 5px;">                 
      <div style="width: 50%;  margin: 4px; padding: 6px; "></div>
      <div style="width: 50%; display: block; padding-left: 25%;padding-right: 3px;">  
        <table 
          width="100%" border="0" cellspacing="0" cellpadding="0"  
          style="margin-bottom: 12px; font-family: 'Poppins'; -webkit-print-color-adjust: exact; -webkit-text-size-adjust:none;" 
        >
          ${subtotal}
          ${FolioDetails?.Payment?.filter(x => x.PaymentClass === 'Refund') .length > 0 && 
            stateInfo?.Page === 'PaymentFolio'
              ? ''
              : OutGoingDocTemplate?.Folio ? HotelMayaTaxItem || '' : data || ''
          }

          <tr 
            class="" 
            style="background-color: #fff;
              color: #2c2c2c;
              font-size: 10px;
              line-height: 15px;"
          >
            ${TotalRounding}
          </tr>
          <tr 
            class="" 
            style="background-color: #fff;
              color: #2c2c2c;
              font-size: 10px;
              line-height: 15px;"
          >
            ${TourismTaxType}
          </tr>
          <tr 
            class="" 
            style="background-color: #fff;
            color: #2c2c2c;
            font-size: 10px;
            line-height: 15px;"
          >
            ${TaxTypeInput}
          </tr>
          <tr 
            class="" 
            style="background-color: #fff;
              color: #2c2c2c;
              font-size: 10px;
              line-height: 15px;"
          >
              <td style="font-weight: 800; padding-left: 6px;">
                TOTAL
              </td>
              <td style="font-weight: 800;  item-align:right; text-align: right; color:#2c2c2c">
                ${isnegativeamount((Number(amountTotal)),'')}
              </td>
              <td style="width:4%;"></td>
          </tr>
        </table>
      </div>   
    </div>`

  const eInvoiceUniqueID = () => {
    const isCondition = FolioDetails?.is_einvoice === true && 
      HotelDetails?.Company?.isEInvIntegrated === true &&
      dateFormat(FolioDetails?.FolioDate, 'yyyy-mm-dd') >= dateFormat(HotelDetails?.Company?.eInvStartDate, 'yyyy-mm-dd') &&
      dateFormat(FolioDetails?.FolioDate, 'yyyy-mm-dd') <= dateFormat(HotelDetails?.Company?.eInvEndDate, 'yyyy-mm-dd')
    
    const resUniqueID = isCondition
        ? 'UUID : ' + (FolioDetails?.einvoice_uniqueID !== '' ? FolioDetails?.einvoice_uniqueID : '-')
        : ''
    return resUniqueID
  }
    console.log('HotelDetails12345', HotelDetails)

  var mapObj: any = {
    //Hotel
    '!!HotelName!!':!HotelDetails?.Company?.name ? '' : `${HotelDetails?.Company?.name} (${HotelDetails?.Company?.companyRegNo})`,
    '!!HotelImage!!': !JSON.parse(localStorage.getItem('Hotel'))?.LogoImage
      ? 'https://hotelx-dev.ifca.io/document/ifca.png'
      : JSON.parse(localStorage.getItem('Hotel'))?.LogoImage.ImageURL,
    '!!HotelEmail!!': !HotelDetails?.['Contact']
      ? ''
      : !HotelDetails?.['Contact']?.['BusinessEmail']
      ? ''
      : HotelDetails?.['Contact']?.['BusinessEmail'],
    '!!SSTRegNo!!': !HotelDetails?.Company?.['sstNo'] || HotelDetails?.Company?.['sstNo'] === 'NA' ? '' : '(SST Reg No: ' + HotelDetails?.Company['sstNo'] + ')',
    '!!TTXRegNo!!': !HotelDetails?.Company?.['ttxNo'] || HotelDetails?.Company?.['ttxNo'] === 'NA' ? '' : '(TTX Reg No: ' + HotelDetails?.Company['ttxNo'] + ')',
    '!!TINNo!!':
      !HotelDetails?.Company?.['tin'] || !HotelDetails?.Company?.['isEInvIntegrated']
        ? ''
        : differenceInDays(
            new Date(dateFormat(FolioDetails?.ClosedDate || FolioDetails?.FolioDate, 'yyyy-mm-dd')),
            new Date(dateFormat(HotelDetails?.Company?.['eInvStartDate'], 'yyyy-mm-dd'))
          ) < 0
          ? ''
          : '(TIN No: ' + HotelDetails?.Company['tin'] + ')',
    '!!HotelMobileNo!!': !HotelDetails?.['Contact']?.['PhoneNo']
      ? ''
      : HotelDetails?.['Contact']?.['PhoneNo'],
    '!!HotelFaxNo!!': HotelDetails?.Contact?.FaxNo || '',
    '!!HotelPhone!!': HotelDetails?.Contact?.MobileNo || '',
    '!!HotelCustomAddress!!':
      ` <p style="color:#2c2c2c">
      ${HotelDetails?.['HotelAddress']?.['address']
        .concat(' ')
        .concat(HotelDetails?.['HotelAddress']?.['postCode'])}
      <br> ${HotelDetails?.['HotelAddress']?.['state']}${' '}${
        HotelDetails?.['HotelAddress']?.['country']
      }</p>` || '',
    '!!HotelWebsite!!': !HotelDetails?.['Contact']?.['WebUrl']
      ? ''
      : HotelDetails?.['Contact']?.['WebUrl'],

    '!!HotelAddress!!': HotelDetails?.['HotelAddress']
      ? AddressConverter({
          address: HotelDetails?.['HotelAddress']?.['address'],
          state: HotelDetails?.['HotelAddress']?.['state'],
          postCode: HotelDetails?.['HotelAddress']?.['postCode'],
          city: HotelDetails?.['HotelAddress']?.['city'],
          country: HotelDetails?.['HotelAddress']?.['country'],
        })
      : '',

    '!!FolioNo!!':
      mode === 'In House' || mode === 'Due Out'
        ? FolioDetailsWithIDs?.RoomCharges[0]?.Description ===
          'Advance - Payment'
          ? FolioDetailsWithIDs?.RoomCharges[0]?.Reason
          : Payment
          ? Charge
          : FolioDetails?.FolioNo || ''
        : FolioDetails?.Payment?.filter(x => x.PaymentClass === 'Refund')
            .length > 0 && stateInfo?.Page === 'PaymentFolio'
        ? FolioDetails?.Payment?.filter(x => x.PaymentClass === 'Refund')[0]
            ?.ReceiptNo
        : FolioDetails?.FolioNo || '',

    '!!FOLIO!!':
      // stateInfo?.Page === 'PaymentFolio'
      //   ? FolioDetails?.Payment?.filter(x => x.PaymentClass === 'Refund')
      //       ?.length > 0
      //     ? 'REFUND VOUCHER'
      //     : 'INVOICE'
      //   : FolioDetails?.FolioNo?.startsWith('CN')
      //   ? 'CREDIT NOTE'
      //   : FolioDetails?.FolioNo?.startsWith('DN')
      //   ? 'DEBIT NOTE'
      //   : FolioDetailsWithIDs?.RoomCharges?.filter(
      //       x => x?.Description === 'Advance - Payment'
      //     )?.length > 0
      //   ? FolioDetailsWithIDs?.RoomCharges[0]?.Reason?.startsWith('RCPT')
      //     ? 'RECEIPT'
      //     : 'INVOICE'
      //   : stateInfo?.Page === 'ConfirmationFolio'
      //   ? 'FOLIO'
      //   : 'INVOICE',
      stateInfo?.Page === 'ConfirmationFolio'
        ? 'FOLIO'
        : stateInfo?.Page === 'PaymentFolio'
        ? FolioDetails?.Payment?.filter(x => x.PaymentClass === 'Refund')
            ?.length > 0
          ? 'REFUND VOUCHER'
          : 'INVOICE'
        : isDocType('CN')
          ? 'CREDIT NOTE'
          : isDocType('DN')
            ? 'DEBIT NOTE'
            : FolioDetailsWithIDs?.RoomCharges?.filter(
                x => x?.Description === 'Advance - Payment'
              )?.length > 0
              ? FolioDetailsWithIDs?.RoomCharges[0]?.Reason?.includes('RCPT')
                ? 'RECEIPT'
                : 'INVOICE'
              : 'INVOICE',

    //Header top
    '!!CashierName!!':
      BIUsersLisiting?.name ||
      JSON.parse(localStorage.getItem('LoggedInUser'))?.name,
    '!!Date!!': dateFormat(FolioDetails?.ClosedDate || FolioDetails?.FolioDate, 'dd/mm/yyyy'),
    '!!RoomNo!!': 
    stateInfo?.Type === 'MasterFolio' ? '' : 
    OutGoingDocTemplate !== null ? isNonGuestFolio === true ?  'N/A' : 
      (stateInfo?.Folio?.FolioType === 'Group Folio' ||
      FolioDetails?.FolioType === 'GroupFolio')
        ? ''
        : FolioDetails?.Registration?.Room?.RoomNo ||
          FolioDetails?.Booking?.Registration?.filter(x => x?.IsPrincipal)?.map(
            y => y?.Room?.RoomNo
          ) : '',

    '!!BeneficiaryName!!': 
        FolioDetails?.BeneficiaryName
          ? FolioDetails?.BeneficiaryName || ''
          : DebtorFolioInfo?.Name || '',
          

    '!!AgentName!!': 
    
    agentname(),
    // '!!AgentName!!': !stateInfo?.Selecteditem?.length
    //   ? !stateInfo?.Selecteditem?.Registration?.Booking?.DebtorAccount
    //     ? ''
    //     : `
    //     Company/Travelling Agent Name:${stateInfo?.Selecteditem?.Registration?.Booking?.DebtorAccount}
    //   `
    //   : !stateInfo?.Selecteditem[0]?.Registration?.Booking?.DebtorAccount
    //   ? ''
    //   : `
    //       Company/Travelling Agent Name:${stateInfo?.Selecteditem[0]?.Registration?.Booking?.DebtorAccount}
    //     `,
    '!!GuestName!!': 
    (OutGoingDocTemplate !== null &&  DebtorFolioInfo !== null ? `<td><td/>` :
    isNonGuestFolio
      ? NonGuestFolioTitle
      : stateInfo?.Folio?.FolioType === 'Group Folio' ||
        FolioDetails?.FolioType === 'GroupFolio'
      ? stateInfo?.PersonalInfo?.booking?.GroupName ||
        FolioDetails?.Booking?.GroupName ||
        FolioDetails?.Booking?.Contact?.FullName ||
        FolioDetails?.Booking?.Registration?.filter(x => x?.IsPrincipal)[0]
          ?.Guest?.Contact?.FullName 
      : stateInfo?.Type === 'MasterFolio' ? FolioDetailsWithIDs?.Booking?.GroupName || FolioDetailsWithIDs?.Booking?.Contact?.FullName
       : FolioDetails?.Registration?.Guest?.Contact?.FullName ||
        FolioDetails?.Booking?.Contact?.FullName ||
        FolioDetailsWithIDs?.Guest?.Contact?.FullName) || stateInfo?.GroupName || '',

    '!!GuestMobileNo!!': 
    (FolioDetails ? OutGoingDocTemplate !== null && isNonGuestFolio ? 'N/A' :
    stateInfo?.Folio?.FolioType === 'Group Folio' ||
        FolioDetails?.FolioType === 'GroupFolio' ? FolioDetails?.Booking?.Registration?.filter(x => x?.IsPrincipal)[0]
        ?.Guest?.Contact?.MobileNo 
        : FolioDetails?.Registration?.Guest?.Contact?.MobileNo || ''
     : FolioDetailsWithIDs?.Guest?.Contact?.MobileNo  ) || ''

        ,
    '!!GuestEmail!!': 
    FolioDetails ?
      OutGoingDocTemplate !== null && isNonGuestFolio ? 'N/A' 
      :  FolioDetails?.Registration?.Guest?.Contact?.Email || ''
        : FolioDetailsWithIDs?.Guest?.Contact?.Email || '',

    '!!GuestAddress!!': 
      FolioDetails?.BeneficiaryAddress
        ? FolioDetails?.BeneficiaryAddress || ''
        : AddressConverter({
            address:DebtorFolioInfo?.Contact?.Address?.address,
            address2: DebtorFolioInfo?.Contact?.Address?.address2,
            city: DebtorFolioInfo?.Contact?.Address?.city,
            country: DebtorFolioInfo?.Contact?.Address?.country,
            postCode: DebtorFolioInfo?.Contact?.Address?.postCode,
            state: DebtorFolioInfo?.Contact?.Address?.state,
          }),
   
  
    '!!IdentityCard!!': 
    FolioDetails ? 
    OutGoingDocTemplate !== null && isNonGuestFolio ? 'N/A' 
    : stateInfo?.Folio?.FolioType === 'Group Folio' ||
    FolioDetails?.FolioType === 'GroupFolio' ? FolioDetails?.Booking?.Registration?.filter(x => x?.IsPrincipal)[0]
    ?.Guest?.Contact?.NRIC || FolioDetails?.Booking?.Registration?.filter(x => x?.IsPrincipal)[0]
    ?.Guest?.Contact?.PassportNo
   
       : FolioDetails?.Registration?.Guest?.Contact?.NRIC ||
          FolioDetails?.Registration?.Guest?.Contact?.PassportNo  || ''

          : FolioDetailsWithIDs?.Guest?.Contact?.NRIC ||
         FolioDetailsWithIDs?.Guest?.Contact?.PassportNo  || '',
      
  

    // '!!ArrivalDate!!': FolioDetails
    //   ? FolioDetails?.Registration
    //     ? !FolioDetails?.Registration?.CheckInDate
    //       ? dateFormat(FolioDetails?.Registration?.ArrivalDate, 'dd/mm/yyyy')
    //       : dateFormat(FolioDetails?.Registration?.CheckInDate, 'dd/mm/yyyy')
    //     : !FolioDetails?.Booking?.Registration[0]?.CheckInDate
    //       ? dateFormat(
    //         FolioDetails?.Booking?.Registration[0]?.ArrivalDate,
    //         'dd/mm/yyyy'
    //       )
    //       : dateFormat(
    //         FolioDetails?.Booking?.Registration[0]?.CheckInDate,
    //         'dd/mm/yyyy'
    //       )
    //   : dateFormat(
    //     FolioDetailsWithIDs?.CheckInDate || FolioDetailsWithIDs?.ArrivalDate,
    //     'dd/mm/yyyy'
    //   ),
    '!!ArrivalDate!!':
    (OutGoingDocTemplate !== null ? isNonGuestFolio ? 'N/A' : 
      isNonGuestFolio || type === 'NoGuest'
        ? ''
        : FolioDetails
        ? FolioDetails?.Registration
          ? dateFormat(FolioDetails?.Registration?.ArrivalDate, 'dd/mm/yyyy')
          : dateFormat(
              FolioDetails?.Booking?.Registration[0]?.ArrivalDate,
              'dd/mm/yyyy'
            )
        : dateFormat(FolioDetailsWithIDs?.ArrivalDate, 'dd/mm/yyyy')
        : isNonGuestFolio || type === 'NoGuest'
        ? ''
        : FolioDetails
        ? FolioDetails?.Registration
          ? dateFormat(FolioDetails?.Registration?.ArrivalDate, 'dd/mm/yyyy')
          : dateFormat(
              FolioDetails?.Booking?.Registration[0]?.ArrivalDate,
              'dd/mm/yyyy'
            )
        : dateFormat(FolioDetailsWithIDs?.ArrivalDate, 'dd/mm/yyyy'))||'',

    '!!ArrivalTime!!':
      isNonGuestFolio || type === 'NoGuest'
        ? ''
        : FolioDetails
        ? FolioDetails?.Registration
          ? !FolioDetails?.Registration?.CheckInDate
            ? dateFormat(FolioDetails?.Registration?.ArrivalDate, 'HH:mm:ss')
            : dateFormat(FolioDetails?.Registration?.CheckInDate, 'HH:mm:ss')
          : !FolioDetails?.Booking?.Registration[0]?.CheckInDate
          ? dateFormat(
              FolioDetails?.Booking?.Registration[0]?.ArrivalDate,
              'HH:mm:ss'
            )
          : dateFormat(
              FolioDetails?.Booking?.Registration[0]?.CheckInDate,
              'HH:mm:ss'
            )
        : dateFormat(
            FolioDetailsWithIDs?.CheckInDate ||
              FolioDetailsWithIDs?.ArrivalDate,
            'HH:mm:ss'
          ),

    '!!DepartureDate!!':
    (OutGoingDocTemplate !== null ? isNonGuestFolio ? 'N/A' : 
   
      isNonGuestFolio || type === 'NoGuest'
        ? ''
        : FolioDetails
        ? dateFormat(
          FolioDetails?.Registration?.CheckOutDate || FolioDetails?.Registration?.DepartureDate || FolioDetails?.Booking?.DepartureDate,
          'dd/mm/yyyy'
        )
        : dateFormat(
            FolioDetailsWithIDs?.CheckOutDate ||
              FolioDetailsWithIDs?.DepartureDate,
            'dd/mm/yyyy'
          )
          :  isNonGuestFolio || type === 'NoGuest'
          ? ''
          : FolioDetails
          ? dateFormat(
            FolioDetails?.Registration?.CheckOutDate || FolioDetails?.Registration?.DepartureDate || FolioDetails?.Booking?.DepartureDate,
            'dd/mm/yyyy'
          )
          : dateFormat(
              FolioDetailsWithIDs?.CheckOutDate ||
                FolioDetailsWithIDs?.DepartureDate,
              'dd/mm/yyyy'
            ))||'',

    '!!DepartureTime!!':
      isNonGuestFolio || type === 'NoGuest'
        ? ''
        : FolioDetails
        ? dateFormat(
          FolioDetails?.Registration?.CheckOutDate || FolioDetails?.Registration?.DepartureDate || FolioDetails?.Booking?.DepartureDate,
          'hh:MM:ss TT'
        )
        : dateFormat(
            FolioDetailsWithIDs?.CheckOutDate ||
              FolioDetailsWithIDs?.DepartureDate,
            'hh:MM:ss TT'
          ),

    '!!BookingNo!!':
    (OutGoingDocTemplate !== null ? isNonGuestFolio ? 'N/A' : 
      FolioDetails?.Registration?.Booking?.BookingNo ||
      FolioDetails?.Booking?.BookingNo ||
      FolioDetailsWithIDs?.Booking?.BookingNo 
      :
      FolioDetails?.Registration?.Booking?.BookingNo ||
      FolioDetails?.Booking?.BookingNo ||
      FolioDetailsWithIDs?.Booking?.BookingNo || '')||''
      ,
    //description
    
         //customtd 
         '!!ICtd!!' : DebtorFolioInfo === null ? `  <td style="font-size:10px; font-weight: 400;   line-height: 18px; width:50%;">
         IC &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: ${   FolioDetails ? 
    OutGoingDocTemplate !== null && isNonGuestFolio ? 'N/A' 
    :
   
        FolioDetails?.Registration?.Guest?.Contact?.NRIC ||
          FolioDetails?.Registration?.Guest?.Contact?.PassportNo || FolioDetailsWithIDs?.Guest?.Contact?.NRIC ||
         FolioDetailsWithIDs?.Guest?.Contact?.PassportNo  || ''

          : FolioDetailsWithIDs?.Guest?.Contact?.NRIC ||
         FolioDetailsWithIDs?.Guest?.Contact?.PassportNo  || FolioDetails?.Registration?.Guest?.Contact?.NRIC ||
         FolioDetails?.Registration?.Guest?.Contact?.PassportNo
          } </td>` : '',
         '!!phonetd!!' :  DebtorFolioInfo === null  ? `  <td style="font-size:10px; font-weight: 400;  line-height: 18px; width:50%;">
         Phone : ${     FolioDetails ? 
          OutGoingDocTemplate !== null && isNonGuestFolio ? 'N/A' :
           FolioDetails?.Registration?.Guest?.Contact?.MobileNo 
         : FolioDetailsWithIDs?.Guest?.Contact?.MobileNo }
        </td>`: '',
         '!!emailtd!!' : DebtorFolioInfo === null  ? `<td style="font-size:10px; font-weight: 400;    width:50%;">
         Email &nbsp;&nbsp;: ${ FolioDetails ?
          OutGoingDocTemplate !== null && isNonGuestFolio ? 'N/A' 
          :  FolioDetails?.Registration?.Guest?.Contact?.Email
            : FolioDetailsWithIDs?.Guest?.Contact?.Email}
       </td>`: '',
    '!!SubTotal!!': isnegativeamount(conditionSub,''),
    '!!NewItem!!':
      FolioDetails !== null
        ? !FolioDetails?.RoomLedger && !FolioDetails?.BillLedger
          ? EachFolioItem2
          : EachFolioItem
        : EachFolioItem,

    '!!NewItem2!!': isinvoice() === true &&
    (!stateInfo?.Page || (stateInfo?.Page && stateInfo?.Page !== 'PaymentFolio') ||
    (stateInfo?.Page === 'PaymentFolio' && FolioDetails?.Payment?.filter(x => x.PaymentClass === 'Refund').length === 0)) ? newitem2 : '',

    '!!ServiceCharge!!': GetServiceChargeTax['Value'],
    '!!TotalServiceCharge!!':
      stateInfo?.Page === 'PaymentFolio' &&
      SelectedItem?.filter(y => y?.PaymentClass === 'Refund')?.length > 0
        ? amtStr(0)
        : SelectedItem.length === 0
        ? amtStr(
            SelectedItemWithIDs?.map(x => x?.ServiceCharge).reduce(
              (a, b) => a + b,
              0
            )
          )
        : amtStr(
            SelectedItem?.map(x => x?.ServiceCharge || null).reduce(
              (a, b) => a + b,
              0
            )
          ),
    '!!Tax!!': GetCurrentGovTax['Value'],
    '!!TotalGovTax!!':
      stateInfo?.Page === 'PaymentFolio' &&
      SelectedItem?.filter(y => y?.PaymentClass === 'Refund')?.length > 0
        ? amtStr(0)
        : isNonGuestFolio
        ? amtStr(
            SelectedItem?.map(x => x?.TaxAmount || null).reduce(
              (a, b) => a + b,
              0
            )
          )
        : SelectedItem.length === 0
        ? amtStr(
            SelectedItemWithIDs?.map(x => x?.TaxAmount).reduce(
              (a, b) => a + b,
              0
            )
          )
        : isSelectedItemGovTax
        ? amtStr(
            SelectedItem?.filter(x => x.TaxType !== 'StateFund')
              ?.filter(x => (x?.TaxType ? x?.TaxType === TaxType.GovTax : null))
              ?.map(x => x?.TaxAmount || null)
              .reduce((a, b) => a + b, 0)
          )
        : amtStr(
            SelectedItem?.filter(x => x.TaxType !== 'StateFund')
              ?.map(x => {
                if (x?.TaxAmount) return x?.TaxAmount
                return 0
              })
              .reduce((a, b) => a + b, 0)
            // SelectedItem?.map(x => x?.TaxAmount || null).reduce(
            //   (a, b) => a + b,
            //   0
            // )
          ),
    '!!TaxSchemeItem!!': OutGoingDocTemplate?.Folio ? HotelMayaTaxItem || '' : data || '',
    '!!TotalRounding!!': (iseInvoice() === true && SelectedItemEO?.length > 0) ? '' :
    `<td style="
    font-weight: 300; padding-left: 6px;">Rounding</td>
       <td style="
       font-weight: 300;  item-align:right;text-align: right;">${isRoundingnegativeamount(Number(amtStr(FolioDetails?.Payment?.filter(z => z?.PaymentClass === 'Rounding')?.map(x => x?.Amount || null)
       .reduce((a, b) => (a + b), 0))), '', documentType(FolioDetails)?.includes('CN') ? 'CN' : '')  || amtStr(0)}</td>`
   ,
    '!!TotalTourismTax!!':
      stateInfo?.Page === 'PaymentFolio' &&
      SelectedItem?.filter(y => y?.PaymentClass === 'Refund')
        ? amtStr(0)
        : amtStr(tourismTaxAmount),
    '!!TaxType!!':
      type === 'REFUND' || documentType(FolioDetails)?.slice(0, 1) === 'D'
        ? ''
        : SelectedItem?.length > 0
        ? stateInfo?.Page === 'PaymentFolio' &&
          SelectedItem?.filter(y => y?.PaymentClass === 'Refund')?.length > 0
          ? ''
          : !FolioDetails?.TaxLedger
          ? ''
          : Heritage === 0 && Levy === 0 && StateFund === 0
          ? ''
          : Heritage > 0
          ? `<td style="
font-weight: 300; padding-left: 6px;">Heritage Tax</td>
   <td style="
   font-weight: 300;  item-align:right;text-align: right;">${amtStr(
     Heritage
   )}</td>
`
          : Levy > 0
          ? `<td style="
font-weight: 300; padding-left: 6px;">Levy Tax</td>
   <td style="
   font-weight: 300; item-align:right; text-align: right;">${amtStr(Levy)}</td>
`
          : ''
        : '',
    // '!!TourismTaxType!!': SelectedItem?.length > 0 ? !FolioDetails?.TaxLedger ? '' : TourismTax === 0 ? '' : TourismTax > 0 ?
    // `<td style="
    // font-weight: 300; padding-left: 6px;">Tourism Tax</td>
    //    <td style="
    //    font-weight: 300;  item-align:right;text-align: center;">${amtStr(
    //     TourismTax
    //    )}</td>` : '': '',
    '!!TourismTaxType!!': !FolioDetails?.TaxLedger
    ? !FolioDetailsWithIDs?.TaxLedger
      ? ''
      :  TourismTax !== 0
      ? `<td style="
  font-weight: 300; padding-left: 0px;">Tourism Tax</td>
     <td style="
     font-weight: 300;  item-align:right;text-align: right;">${amtStr(
      isnegativeamount(Number(TourismTax),'')
     )}</td>`
      : `<td style="
      font-weight: 300; padding-left: 6px;">Tourism Tax</td>
         <td style="
         font-weight: 300;  item-align:right;text-align: right;">${amtStr(
          isnegativeamount(Number(TourismTax),'')
         )}</td>`
    :  TourismTax !== 0
    ? `<td style="
  font-weight: 300; padding-left: 0px;">Tourism Tax</td>
     <td style="
     font-weight: 300;  item-align:right;text-align: right;">${
      isnegativeamount(Number(TourismTax),'')
     }</td>`
    : '',
    '!!TaxTypeAmount!!': amtStr(
      FolioDetails?.TaxLedger?.map(x => x?.TaxAmount).reduce((a, b) => a + b, 0)
    ),
    '!!TotalAmount!!': isnegativeamount((Number(TotalAmount)-Number(TotalEO)),''),
    '!!QtyHeader!!': '<th style="font-weight: 500;  width:100px;">Qty</th>',
    '!!OutStandingBalance!!': 
  outstandingbalanceFunc()
     ,
    '!!BankDetail!!':
      isnonguestandsuspend() === true 
        ? `
      
    <div style="font-size: 10px; padding-top:25px; padding-bottom:50px;  ">
    <table border="1" cellspacing="0" cellpadding="0" style=";width: 40%;text-alignment:center; margin-left: auto;
                                    margin-right: auto;">
      <tr>
        <td>
          <table border="0" cellpadding=0 cellspacing=0>
            <tr>
              <td colspan="3" style="padding-left:15px;">Our bank details as below :</td>
            </tr>
            <tr>
              <td style="width:5%; padding-left:15px;">Beneficiary</td>
              <td style="width:5px;">:</td>
              <td style="width:12%;">SDB Properties Sdn Bhd</td>
            </tr>
            <tr>
              <td style="width:5%;padding-left:15px;">Bank Name</td>
              <td style="width:5px;">:</td>
              <td style="width:12%;">Public Bank Berhad</td>
            </tr>
            <tr>
              <td style="width:5%;padding-left:15px;">Bank Address</td>
              <td style="width:5px;">:</td>
              <td style="width:12%;">Menara Public Bank </td>
            </tr>
            <tr>
              <td style="width:5%;padding-left:15px;"></td>
              <td style="width:5px;"></td>
              <td style="width:12%;"> 146, Jalan Ampang </td>
            </tr>
            <tr>
              <td style="width:5%;padding-left:15px;"></td>
              <td style="width:5px;"></td>
              <td style="width:12%;"> 50450 Kuala Lumpur,Malaysia </td>
            </tr>
            <tr>
              <td style="width:5%;padding-left:15px;">Account No.</td>
              <td style="width:5px;">:</td>
              <td style="width:12%;"> 310-3935-218 </td>
            </tr>
            <tr>
              <td style="width:5%;padding-left:15px;">Bank ID</td>
              <td style="width:5px;">:</td>
              <td style="width:12%;"> 190055-A </td>
            </tr>
            <tr>
              <td style="width:5%;padding-left:15px;">Swift Code</td>
              <td style="width:2%;">:</td>
              <td style="width:12%;"> PBBEMYKL </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
      `
        : ``,
    '!!IsSmfooter!!':
      isnonguestandsuspend() === true
        ? ``
        : `    	
  <hr style="padding-right:10px;" />
  <table width="100%" border="0" cellspacing="0" cellpadding="0" style="padding-top:10px; padding-bottom:10px;">
  <td style="font-size:10px; font-weight: 400;   line-height: 15px; width:25%; text-align: justify; color:#2c2c2c"> * I agree that <span style="font-weight: bold;">i</span> am personally liable for the payment of the following statement and if the person, company or association indicated by me as being responsible for payment of the same does not do so, that my liability for such payment shall be joint and several with such person, company or association. </td>
  <td style="font-size:10px; font-weight: 400;  line-height: 15px; width:20%; text-align: right;"></td>
  <td style="font-size:10px; font-weight: 400;   line-height: 15px; width:25%; text-align: left; color:#2c2c2c">
  <hr style="padding-right:10px;" > Guest Signature
  </td>
  </tr>
  </table>`,
    '!!customheightfooter!!': isnonguestandsuspend() === true ? `10%` : `20%`, //beta
    '!!customheightcontent!!':
      isnonguestandsuspend() === true ? `110px` : `220px`,
    '!!InvoiceRemark!!': HotelDetails?.DocumentRemark?.replace(/<p/g, '<div')?.replace(/<\/p>/g, '</div>') || '',
    '!!eInvoiceQR!!': !stateInfo?.Page || stateInfo?.Page === 'DueOutFolio' ||
      (stateInfo?.Page === 'PaymentFolio' && FolioDetails?.Payment?.filter(x => x.PaymentClass === 'Refund').length === 0)  
        ? eInvoiceQr() : '',
    '!!UID!!': eInvoiceUniqueID(),
    '!!ExternalOutlet!!': iseInvoice() === true && (
        !stateInfo?.Page || 
        (stateInfo?.Page === 'PaymentFolio' && FolioDetails?.Payment?.filter(x => x.PaymentClass === 'Refund').length === 0) 
      ) ? externalOutlet : '',
    '!!AmountItem!!': selectedItemDesc?.length > 0 ? AmountItem: '',
  }
  let Hoteltemplate
  var FormContent =
  // OutGoingDocTemplate !== null
  // ? OutGoingDocTemplate?.Folio?.replace(
  //     /!!FOLIO!!|!!HotelImage!!|!!customheightfooter!!|!!ICtd!!|!!phonetd!!|!!emailtd!!|!!OutStandingBalance!!|!!SubTotal!!|!!customheightcontent!!|!!IsSmfooter!!|!!QtyHeader!!|!!BankDetail!!|!!FolioNo!!|!!NewItem!!|!!NewItem2!!|!!TotalAmount!!|!!TotalTourismTax!!|!!TotalGovTax!!|!!TotalRounding!!|!!TotalServiceCharge!!|!!TaxTypeAmount!!|!!TaxType!!|!!TourismTaxType!!|!!Tax!!|!!ServiceCharge!!|!!AgentName!!|!!GuestAddress!!|!!IdentityCard!!|!!CashierName!!|!!SSTRegNo!!|!!TTXRegNo!!|!!HotelWebsite!!|!!HotelMobileNo!!|!!HotelCustomAddress!!|!!HotelPhone!!|!!HotelFaxNo!!|!!HotelEmail!!|!!HotelAddress!!|!!BookingNo!!|!!DepartureTime!!|!!DepartureDate!!|!!GuestName!!|!!HotelName!!|!!Date!!|!!RoomNo!!|!!ArrivalTime!!|!!GuestMobileNo!!|!!ArrivalDate!!|!!GuestEmail!!|!!BeneficiaryName!!|!!BeneficiaryAddress!!|!!InvoiceRemark!!/gi,
  //     function (matched) {
  //       // var x = mapObj[matched]
  //       // console.log(x)
  //       return mapObj[matched]
  //     }
  //   )
  // :
   HotelXFolio?.replace(
          /!!FOLIO!!|!!HotelImage!!|!!FolioNo!!|!!SubTotal!!|!!NewItem!!|!!NewItem2!!|!!TotalAmount!!|!!TotalTourismTax!!|!!TotalGovTax!!|!!TotalRounding!!|!!TotalServiceCharge!!|!!TaxTypeAmount!!|!!TaxType!!|!!TourismTaxType!!|!!Tax!!|!!ServiceCharge!!|!!AgentName!!|!!GuestAddress!!|!!IdentityCard!!|!!CashierName!!|!!SSTRegNo!!|!!TTXRegNo!!|!!TINNo!!|!!HotelWebsite!!|!!HotelMobileNo!!|!!HotelEmail!!|!!HotelAddress!!|!!BookingNo!!|!!DepartureTime!!|!!DepartureDate!!|!!GuestName!!|!!HotelName!!|!!Date!!|!!RoomNo!!|!!ArrivalTime!!|!!GuestMobileNo!!|!!ArrivalDate!!|!!GuestEmail!!|!!BeneficiaryName!!|!!BeneficiaryAddress!!|!!InvoiceRemark!!|!!TaxSchemeItem!!|!!eInvoiceQR!!|!!UID!!|!!ExternalOutlet!!|!!AmountItem!!/gi,
          function (matched) {
            // var x = mapObj[matched]
            // console.log(x)
            return mapObj[matched]
          }
        )
  // console.log(FormContent, 'FormContent')
  const printForm = () => {
    var win = window?.open()
    win?.document?.write(FormContent)
    win?.document?.close()
    setTimeout(() => {
      win?.print()
    }, 100)
  }

  console.log(FormContent,'FormContent')
  const paymentFolioSelection = type => {
    dispatch({
      type: 'DocSelectionDialog',
      payload: true,
    })
    dispatch({
      type: 'ButtonType',
      payload: type,
    })
    dispatch({
      type: 'DocList',
      payload: [
        {
          DocumentNo: FolioDetails?.Payment?.filter(x => x.PaymentClass === 'Refund').length >
            0 && stateInfo?.Page === 'PaymentFolio'
            ? FolioDetails?.Payment?.filter(x => x.PaymentClass === 'Refund')[0]
                ?.ReceiptNo
            : null,
          DocumentType: FolioDetails?.Payment?.filter(x => x.PaymentClass === 'Refund').length >
            0 && stateInfo?.Page === 'PaymentFolio'
            ? FolioDetails?.Payment?.filter(x => x.PaymentClass === 'Refund')[0]
                ?.DocumentType
            : null,
        },
        {
          DocumentNo: FolioDetails?.FolioNo,
          DocumentType: FolioDetails?.DocumentType
        },
        {
          DocumentNo: PaymentDetails?.ReceiptNo,
          DocumentType: PaymentDetails?.DocumentType
        }
      ],
    })
    dispatch({
      type: 'DocSelected',
      payload: FolioDetails?.DocumentType === null 
        ? FolioDetails?.FolioNo
        : FolioDetails?.DocumentType,
    })
  }
  const docSelectionPrint = (docSelectionPrint) => {  
    let Template;
    if (docSelectionPrint.length > 0) {
      const isHtrf = state?.DocList?.find(x => 
        x?.DocumentType === null && x?.DocumentNo?.startsWith('HTRF') ||
        x?.DocumentType !== null && x?.DocumentType?.startsWith('HTRF')
      )
      Template = docSelectionPrint.map((x, i) =>
        (x?.ID?.startsWith('HTRF') && x?.DocumentType === null) || (x?.DocumentType?.startsWith('HTRF') && x?.DocumentType !== null)
          ? FormContent
          : (x?.ID?.startsWith('INV') && x?.DocumentType === null) || (x?.DocumentType?.startsWith('INV') && x?.DocumentType !== null)
          ? isHtrf ? InvoiceTemplate : FormContent
          : ReceiptTemplate
      );
      console.log(Template);
  
      var win = window?.open();
      win?.document?.write(Template.join('<div style="page-break-after: always;"></div>')); 
      win?.document?.close();
  
      setTimeout(() => {
        win?.print();
      }, 100);
    }
  };
  
  //! Share
  const docSelectionShare = (docSelectionShare) => {
    const TemplateBlob = docSelectionShare.map((x, i) => {
      const blobContent = htmlConverter({
        FormContent,
        Name:
          'Receipt' +
          (PaymentDetails
            ? documentType(PaymentDetails)?.slice(0, 2) === 'RF' ||
              documentType(PaymentDetails)?.slice(0, 1) === 'D'
              ? PaymentDetails?.Folio?.FolioNo
              : PaymentDetails?.ReceiptNo
            : documentType(AdvancePaymentDetails)?.slice(0, 2) === 'RF' ||
              documentType(AdvancePaymentDetails)?.slice(0, 1) === 'D'
            ? AdvancePaymentDetails?.Folio?.FolioNo
            : AdvancePaymentDetails?.ReceiptNo),
      });

      const checkDocType = (x?.ID?.startsWith('HTRF') && x?.DocumentType === null) || 
        (x?.DocumentType?.startsWith('HTRF') && x?.DocumentType !== null) ||
        (x?.ID?.startsWith('INV') && x?.DocumentType === null) || 
        (x?.DocumentType?.startsWith('INV') && x?.DocumentType !== null)
  
      return checkDocType
        ? htmlConverter({
            FormContent,
            Name:
              'Folio' + FolioDetails?.FolioNo ||
              FolioDetailsWithIDs?.Booking?.BookingNo,
          })
        : blobContent;
    });
  
    setSimple(true);
    onSubmit(TemplateBlob);
  };
  
  const defaultShare = () => {
    const blob = htmlConverter({
      FormContent,
      Name:
        'Folio' + FolioDetails?.FolioNo ||
        FolioDetailsWithIDs?.Booking?.BookingNo,
    })
    setSimple(true)
    onSubmit(blob)
  }
  console.log('test123')
  let customcomponent = false
const iscustom = OutGoingDocTemplate ? true : false
  const CustomBidocumentComponentHandler = () => {
    if (HotelDetails?.ID === 'a75ad068-a334-4a7e-ad4b-5d93894ed230' || HotelDetails?.ID === 'f6b932aa-dff6-4d47-b9d2-bbd5314dddc9') {// maya | sunway
      customcomponent = true

      return <BIDocumentMaya mode={mode} type={type} />;
    } else if (HotelDetails?.ID === '683b1eba-aad4-4b3e-aeee-c4a99f1a23a5' || HotelDetails?.ID === '33158493-a408-4084-9f45-8bad2a9a5319') {//phkk | syafiqhotel
      customcomponent = true
      return <BIDocumentPromenade mode={mode} type={type} />;
    } else if (HotelDetails?.ID === 'e46fd9f2-ec0a-47bc-a214-a3ea843f20c6' || HotelDetails?.ID === 'fe1e6413-7599-4b54-8981-3b9c02ab677e') {//phbtu | uat(test)sabahhotel
      customcomponent = true
      return <BIDocumentPromenadeBintulu mode={mode} type={type} />;
    } else if (HotelDetails?.ID === '4cbd20d7-d2d0-47ea-a006-3703f65664cc' || HotelDetails?.ID === 'b7bd96bc-bd33-4650-8c49-577fea38bc94' || HotelDetails?.ID === 'cc7adbff-d42b-4bd3-a765-fbd96bc69369' 
        || HotelDetails?.ID === '0dbae54c-27c7-472a-92a5-6a8fe9481dfc' || HotelDetails?.ID === 'a0a80ba3-c5eb-40b8-8002-b11fc514e471'
        || HotelDetails?.ID === 'c3ec67a0-0041-4a3f-bd2e-bd3bb6881eb6' || HotelDetails?.ID === 'f28c4f3e-d68d-4f7b-a963-d44c97b3346c') { // Hotel123 | imperialnew | imperialold | uat(trial)imperialriverbank | uat(trial)imperialhotelmiri | Hotel Khan | maple
      customcomponent = true
      return <BIDocumentImperial mode={mode} type={type} />;
    } else {
      customcomponent = false
      return (<>
      <div
          style={{ fontSize: 12, padding: 5 }}
          dangerouslySetInnerHTML={{
            __html: FormContent,
          }}
        ></div>
      {
        
        FolioDetails?.Payment?.filter(x => x.PaymentClass === 'Refund')
          .length > 0 && stateInfo?.Page === 'PaymentFolio' ? (
          <div
            className="m-t-15"
            style={{ fontSize: 12, padding: 5 }}
            dangerouslySetInnerHTML={{ __html: InvoiceTemplate }}
          />
        ) : null}
        {(stateInfo?.Page === 'PaymentFolio' ||
        stateInfo?.Page === 'NonGuestFolio') ? (
          <div
            className="m-t-15"
            style={{ fontSize: 12, padding: 5 }}
            dangerouslySetInnerHTML={{ __html: ReceiptTemplate }}
          />
        ) : null}</>
        
      );
    }
  };
  // Example usage

  const onGoBack = () => {
    if (stateInfo?.paymentSelected?.length > 0) {
      window.history.go(-2)
    } else {
      history.goBack()
    }
  }

  return loading || loadingSum || loadingInsert || Loadingtemp ? (
    <Loading />
  ) : (
    <>
      <MainHeader
        mainBtn="back"
        onClick={() => {

          window.scrollTo(0, 0)//fetchmore will error if scroll down back
          setTimeout(() => {
         
         
          stateInfo?.mode || isNonGuestFolio || type === 'Checkout Adjustment'
            ? onGoBack()
            : history.push('/businessinsight/digitaldocument/folio', {
                StartDate: stateInfo.StartDate,
                EndDate: stateInfo.EndDate,
                name: stateInfo.name,
              })
            }, 500)
        }}
        smTitle="HMS"
        title={JSON.parse(localStorage.getItem('Hotel'))?.HotelName}
        routeSegments={[
          { name: 'Digital Document' },
          { name: 'Digital Document' },
          { name: 'Folio', current: true },
        ]}
        // rightRouteSegments={[
        //   {
        //     name: `${
        //       FolioDetails !== null
        //         ? FolioDetails?.FolioNo
        //         : FolioDetailsWithIDs?.Booking?.BookingNo
        //     }`,
        //     current: true,
        //   },
        // ]}
      />

      <ContentWrapper float>
     {CustomBidocumentComponentHandler()} 
      
      </ContentWrapper>
{
  customcomponent ? null
  : (<>
  { stateInfo.Page === 'PaymentInfo' && state.DocSelected.startsWith('INV')
        ? ShareDialog({
            ShareTitle: ShareButton.title,
            Title: `Share ${type ? type : 'Folio'}--${
              FolioDetails?.FolioNo || FolioDetailsWithIDs?.Booking?.BookingNo
            }`,
            URL: ShareButton.url,
            setSimple: setSimple,
            simple: simple,
            EmailShare: emailShare,
            setSimpleEmail: setSendEmail,
            isDirect: true,
            phoneNumber: FolioDetails?.Registration?.Guest?.Contact?.MobileNo?.replace(/\s/g, '') ?? undefined,
            setOpenPhoneNum: setOpenPhoneNum
          })
        : ShareDialog({
            ShareTitle: ShareReceiptButton.title,
            Title: `Share ${
              type
                ? type === TypesRoute.Refund
                  ? 'Refund Voucher'
                  : type
                : 'Receipt'
            }--${
              PaymentDetails
                ? documentType(PaymentDetails)?.slice(0, 2) === 'RF' ||
                  documentType(PaymentDetails)?.slice(0, 1) === 'D'
                  ? PaymentDetails?.Folio?.FolioNo
                  : PaymentDetails?.ReceiptNo
                : documentType(AdvancePaymentDetails)?.slice(0, 2) === 'RF' ||
                  documentType(AdvancePaymentDetails)?.slice(0, 1) === 'D'
                ? AdvancePaymentDetails?.Folio?.FolioNo
                : AdvancePaymentDetails?.ReceiptNo
            }`,
            URL: ShareReceiptButton.url,
            setSimple: setSimple,
            simple: simple,
            EmailShare: emailShare,
            setSimpleEmail: setSendEmail,
            isDirect: true,
            phoneNumber: FolioDetails?.Registration?.Guest?.Contact?.MobileNo?.replace(/\s/g, '') ?? undefined,
            setOpenPhoneNum: setOpenPhoneNum
          })}
      {ShareDialog({
        ShareTitle: ShareButton.title,
        Title: `Share ${type ? type : 'Folio'}--${
          type ? FolioDetails?.FolioNo || FolioDetailsWithIDs?.Booking?.BookingNo : state?.selectedarr?.length > 0 ? state?.selectedarr?.map(x => x?.ID) : FolioDetails?.FolioNo || ''
        }`,
        URL: ShareButton.url,
        setSimple: setSimple,
        simple: simple,
        EmailShare: emailShare,
        setSimpleEmail: setSendEmail,
        isDirect: true,
        phoneNumber: FolioDetails?.Registration?.Guest?.Contact?.MobileNo?.replace(/\s/g, '') ?? undefined,
        setOpenPhoneNum: setOpenPhoneNum
      })}
      <DocSelectionDialog
        state={state}
        dispatch={dispatch}
        docSelectionPrint={docSelectionPrint}
        docSelectionShare={docSelectionShare}
      />

      <PhoneNumDialog
        open={openPhoneNum}
        setOpen={setOpenPhoneNum}
        shareTitle={ShareButton.title}
        URL={ShareButton.url}
      />

      
  {Einvoice ? <RefreshButton
 onClick={() => refetch()}  
               floatTotalAmt
              /> : null}
            
      
      <Footer
        options={[
          {
            name: 'Share',
            disabled: handlePermDisabled({
              hotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
              permEnum:
                HotelPermission.BusinessInsightDigitalDocumentFolioShare,
            }),

            onClick: () =>
              stateInfo?.Page === 'PaymentFolio'
                ? paymentFolioSelection('Share')
                : defaultShare(),
            color: 'primary',
          },
          {
            name: 'print',
            disabled: handlePermDisabled({
              hotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
              permEnum:
                HotelPermission.BusinessInsightDigitalDocumentFolioPrint,
            }) || Einvoice,
            onClick: () =>
              stateInfo?.Page === 'PaymentFolio'
                ? paymentFolioSelection('Print')
                : printForm(),
            color: 'primary',
          },
        ]}
      />
  </>)
}
      
      <SnackBarMsg open={openSnackBar} message={snackBarMessage} />
    </>
  )
}
